import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

function SignUp() {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const navigate = useNavigate();

   const handleSignUp = (e) => {
      e.preventDefault();
      // Handle sign-up logic here
      if (password === confirmPassword) {
         console.log('Email:', email, 'Password:', password);
         // Redirect or display message on success
         navigate('/signin');
      } else {
         alert('Passwords do not match');
      }
   };

   return (
      <div className="auth-container">
         <h2>회원가입</h2>
         <form onSubmit={handleSignUp}>
            <div className="form-group">
               <label>E-mail</label>
               <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-group">
               <label>비밀번호</label>
               <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <div className="form-group">
               <label>비밀번호 확인</label>
               <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
            </div>
            <button type="submit" className="auth-button">
               회원가입
            </button>
         </form>
         <p>
            계정이 있으신가요? <a href="/signin">로그인</a>
         </p>
      </div>
   );
}

export default SignUp;
