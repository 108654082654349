import React from 'react';

function About() {
   return (
      <div>
         <h2> ABOUT AI·4·All </h2>
         <p>
            AI·4·All 글로벌 시장에서 스타트업에 대한 투자 기회를 확대하고 있으며, 다양한 기술 및 서비스 분야의 혁신적인 스타트업에 대한 정보를
            제공하고 있습니다.
         </p>
         <p>
            이 플랫폼은 스타트업 분석 툴과 최신 트렌드 정보를 제공하여 투자자가 효율적으로 의사 결정을 내릴 수 있도록 돕습니다. 주요 기능으로는
            스타트업 정보 검색, 성장 가능성 예측, 포트폴리오 관리 등이 포함되어 있습니다.
         </p>
         <p>우리의 목표는 기술 기반 스타트업과의 협력을 통해 함께 성장하고 혁신을 이루는 것입니다.</p>
      </div>
   );
}

export default About;
