import React from 'react';
import CheckboxInput from './CheckboxInput';

function FundingLevelFilter({ fundingLevelFilter, setFundingLevelFilter }) {
   const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      setFundingLevelFilter((prevFilters) => (checked ? [...prevFilters, value] : prevFilters.filter((filter) => filter !== value)));
   };

   return (
      <div className="filter-box">
         <h4 className="filter-title">투자단계</h4>
         <CheckboxInput value="seed" label="seed" checked={fundingLevelFilter.includes('seed')} onChange={handleCheckboxChange} />
         <CheckboxInput value="pre-A" label="pre-A" checked={fundingLevelFilter.includes('pre-A')} onChange={handleCheckboxChange} />
         <CheckboxInput value="series A" label="series A" checked={fundingLevelFilter.includes('series A')} onChange={handleCheckboxChange} />
         <CheckboxInput value="series B" label="series B" checked={fundingLevelFilter.includes('series B')} onChange={handleCheckboxChange} />
         <CheckboxInput value="series C" label="series C" checked={fundingLevelFilter.includes('series C')} onChange={handleCheckboxChange} />
         <CheckboxInput value="series G" label="series G" checked={fundingLevelFilter.includes('series G')} onChange={handleCheckboxChange} />
         <CheckboxInput value="pre-IPO" label="pre-IPO" checked={fundingLevelFilter.includes('pre-IPO')} onChange={handleCheckboxChange} />
         <CheckboxInput value="IPO" label="IPO" checked={fundingLevelFilter.includes('IPO')} onChange={handleCheckboxChange} />
         <CheckboxInput value="M&A" label="M&A" checked={fundingLevelFilter.includes('M&A')} onChange={handleCheckboxChange} />
         <CheckboxInput value="비공개" label="비공개" checked={fundingLevelFilter.includes('비공개')} onChange={handleCheckboxChange} />
      </div>
   );
}

export default FundingLevelFilter;
