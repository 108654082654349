// src/App.js
import React, { useState } from 'react';
import axios from 'axios';
import './ValueCompany.css';

// const apiKey =
//    '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';
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
function App() {
   const [formData, setFormData] = useState({
      companyName: '',
      capital: '',
      revenue: '',
      employees: '',
      description: '',
      additionalInfo: '',
   });

   const [response, setResponse] = useState(null);
   const [loading, setLoading] = useState(false);

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const openAIInput = `
      A start up company is applying for investment consideration with the following details:
      - Company Name: ${formData.companyName}
      - Capital: ${formData.capital}
      - Revenue: ${formData.revenue}
      - Number of Employees: ${formData.employees}
      - Business Description: ${formData.description}
      - Additional Information: ${formData.additionalInfo}

      Provide an investment evaluation and score the company's viability from 1 to 10.

      - Give me {response.score} and {response.analysis}. 
      - {response.score} must be out of 10, and to 1st decimal point.
      - {response.analysis} must be in brief, polite Korean.
      - Give me response in the format below.
        <table>
            <tbody>
                <tr>
                    <strong>투자적격점수 </strong> {response.score} / 10점
                </tr>
                <p></p>
                <tr>
                    <strong>분석결과</strong>
                    {response.analysis}
                </tr>
            </tbody>
        </table>
    `;

      try {
         const result = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
               model: 'gpt-4', // Change to 'gpt-3.5-turbo' if you don't have GPT-4 access
               messages: [
                  { role: 'system', content: 'You are a financial expert evaluating companies for investment.' },
                  { role: 'user', content: openAIInput },
               ],
               max_tokens: 1000,
            },
            {
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${apiKey}`,
               },
            }
         );

         const gptResponse = result.data.choices[0].message.content.trim();
         setResponse({ analysis: gptResponse, score: Math.floor(Math.random() * 10) + 1 }); // Mock score
      } catch (error) {
         console.error('Error fetching evaluation:', error);
      } finally {
         setLoading(false);
      }
   };

   return (
      <div className="container">
         <h1>투자 적격 평가 (약식) </h1>
         <p>AI·4·All가 축적한 스타트업 성장성 데이터베이스 및 Pre Trained AI 에 기반하여, 귀사로의 투자 적격성을 평가합니다. </p>
         <p>
            아래 투자 양식은 기본적인 형식에 맞추어 자유롭게 작성하시면 되며, 제출받은 정보는 AI·4·All의 데이터베이스에 축적되지 않으며, AI를 훈련하는
            정보로도 이용되지 않습니다. 더불어 제출을 통해 수취하신 적격평가 의견은 AI·4·All의 공식적인 입장은 아님을 알려드립니다.
         </p>
         <form onSubmit={handleSubmit} className="evaluation-form">
            <div className="form-group">
               <label>회사명</label>
               <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} required />
            </div>
            <div className="form-group">
               <label>자본금</label>
               <input type="text" name="capital" value={formData.capital} onChange={handleChange} required />
            </div>
            <div className="form-group">
               <label>매출</label>
               <input type="text" name="revenue" value={formData.revenue} onChange={handleChange} required />
            </div>
            <div className="form-group">
               <label>임직원수</label>
               <input type="text" name="employees" value={formData.employees} onChange={handleChange} required />
            </div>
            <div className="form-group">
               <label>사업설명</label>
               <textarea name="description" value={formData.description} onChange={handleChange} required />
            </div>
            <div className="form-group">
               <label>추가검토 요청사항 (예 : 진행중인 거래 등)</label>
               <textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} />
            </div>
            <button type="submit" disabled={loading} className="submit-btn">
               {loading ? '평가중...' : '제출'}
            </button>
         </form>

         {response && (
            <div className="response-container">
               <h2>[ {formData.companyName} ] 투자 적격 평가</h2>
               {/* <table>
                  <tbody>
                     <tr align="left">
                        <strong>투자적격점수 </strong> {response.score} / 10점
                     </tr>
                     <p></p>
                     <tr align="left">
                        <strong>분석결과</strong>
                        <p>{response.analysis}</p>
                     </tr>
                  </tbody>
               </table> */}
               {/* {response.analysis} */}
               <div dangerouslySetInnerHTML={{ __html: response.analysis }} />
            </div>
         )}
      </div>
   );
}

export default App;
