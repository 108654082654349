// MainContent.js
import React from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Companies from './pages/Companies';
import Contact from './pages/Contact';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Demo from './pages/Demo';
import Demo2 from './pages/Demo2';
import MockIR from './pages/MockIR';
import MockirResult from './pages/MockirResult';
import Dataroom from './pages/Dataroom';
import FourSales from './pages/FourSales';
import FourSalesPrivate from './pages/FourSalesPrivate';
import Chatbot from './pages/Chatbot';
import FinalPage from './pages/FinalPage';
import ValueCompany from './pages/ValueCompany';
import Ilman from './pages/Ilman';
import companies from './data/list.json';

const MainContent = ({ handleSignIn }) => {
   const location = useLocation(); // Get the current location

   // Determine background color based on the current path
   const getBackgroundColor = () => {
      switch (location.pathname) {
         case '/startuproom/mockir':
            return 'rgb(12,45,72)'; // Background color for this path
         // Add other cases as needed
         default:
            return 'white'; // Default background color
      }
   };

   return (
      <div className="main-content" style={{ backgroundColor: getBackgroundColor() }}>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/companies" element={<Companies companies={companies} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signin" element={<SignIn onSignIn={handleSignIn} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/valuecompany" element={<ValueCompany />} />
            <Route path="/chatbot" element={<Chatbot />} />
            <Route path="/finalPage" element={<FinalPage />} />

            <Route path="/dataroom" element={<Demo companies={companies} />}>
               <Route index element={<Dataroom companies={companies} />} />
               <Route path="ilman" element={<Ilman />} />
               <Route path="foursales" element={<FourSales />} />
            </Route>

            <Route path="/startuproom" element={<Demo2 companies={companies} />}>
               <Route index element={<FourSalesPrivate companies={companies} />} />
               <Route path="mockir" element={<MockIR />} />
               <Route path="mockirResult" element={<MockirResult />} />
            </Route>

            <Route path="/private" element={<FourSalesPrivate />} />
         </Routes>
      </div>
   );
};

export default MainContent;
