// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Demo.css */
html,
body {
   height: 100%;
   margin: 0;
   padding: 0;
}

#root {
   height: 100%;
}

.demo-container {
   display: flex;
   height: 100vh; /* Full height of the viewport */
   width: 100%; /* Full width of the viewport */
}

.dataroom-container {
   flex: 0 0 70%; /* 65% of the width */
   padding: 10px;
   box-sizing: border-box;
}

.chatbot-container {
   flex: 0 0 30%; /* 35% of the width */
   /* padding: 10px; */
   box-sizing: border;
}

.page-title {
   display: flex;
   min-height: 25px;
   font-size: 25px;
   margin: 10px;
   font-weight: bold;
}

.mockIr-container {
   flex: 0 0 70%;
}

.pillar {
   flex: 0 0 40%;
}

.demo-container2 {
   display: flex;
   justify-content: space-between;
   width: 100%; /* Full width of the viewport */
}

.dataroom-container2 {
   flex: 0 0 70%; /* 65% of the width*/
   display: flex;
   padding: 10px;
   box-sizing: border-box;
   justify-content: center;
}

.dataroom-container2.interview {
   flex: 0 0 100%;
}

.demo-container2.interview {
   background-color: rgb(12, 45, 72);
   justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Demo.css"],"names":[],"mappings":"AAAA,aAAa;AACb;;GAEG,YAAY;GACZ,SAAS;GACT,UAAU;AACb;;AAEA;GACG,YAAY;AACf;;AAEA;GACG,aAAa;GACb,aAAa,EAAE,gCAAgC;GAC/C,WAAW,EAAE,+BAA+B;AAC/C;;AAEA;GACG,aAAa,EAAE,qBAAqB;GACpC,aAAa;GACb,sBAAsB;AACzB;;AAEA;GACG,aAAa,EAAE,qBAAqB;GACpC,mBAAmB;GACnB,kBAAkB;AACrB;;AAEA;GACG,aAAa;GACb,gBAAgB;GAChB,eAAe;GACf,YAAY;GACZ,iBAAiB;AACpB;;AAEA;GACG,aAAa;AAChB;;AAEA;GACG,aAAa;AAChB;;AAEA;GACG,aAAa;GACb,8BAA8B;GAC9B,WAAW,EAAE,+BAA+B;AAC/C;;AAEA;GACG,aAAa,EAAE,oBAAoB;GACnC,aAAa;GACb,aAAa;GACb,sBAAsB;GACtB,uBAAuB;AAC1B;;AAEA;GACG,cAAc;AACjB;;AAEA;GACG,iCAAiC;GACjC,uBAAuB;AAC1B","sourcesContent":["/* Demo.css */\r\nhtml,\r\nbody {\r\n   height: 100%;\r\n   margin: 0;\r\n   padding: 0;\r\n}\r\n\r\n#root {\r\n   height: 100%;\r\n}\r\n\r\n.demo-container {\r\n   display: flex;\r\n   height: 100vh; /* Full height of the viewport */\r\n   width: 100%; /* Full width of the viewport */\r\n}\r\n\r\n.dataroom-container {\r\n   flex: 0 0 70%; /* 65% of the width */\r\n   padding: 10px;\r\n   box-sizing: border-box;\r\n}\r\n\r\n.chatbot-container {\r\n   flex: 0 0 30%; /* 35% of the width */\r\n   /* padding: 10px; */\r\n   box-sizing: border;\r\n}\r\n\r\n.page-title {\r\n   display: flex;\r\n   min-height: 25px;\r\n   font-size: 25px;\r\n   margin: 10px;\r\n   font-weight: bold;\r\n}\r\n\r\n.mockIr-container {\r\n   flex: 0 0 70%;\r\n}\r\n\r\n.pillar {\r\n   flex: 0 0 40%;\r\n}\r\n\r\n.demo-container2 {\r\n   display: flex;\r\n   justify-content: space-between;\r\n   width: 100%; /* Full width of the viewport */\r\n}\r\n\r\n.dataroom-container2 {\r\n   flex: 0 0 70%; /* 65% of the width*/\r\n   display: flex;\r\n   padding: 10px;\r\n   box-sizing: border-box;\r\n   justify-content: center;\r\n}\r\n\r\n.dataroom-container2.interview {\r\n   flex: 0 0 100%;\r\n}\r\n\r\n.demo-container2.interview {\r\n   background-color: rgb(12, 45, 72);\r\n   justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
