import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './FourSales.css';

// import axios from 'axios';
const FourSales = () => {
   const [loading, setLoading] = useState(false); // State to manage loading screen
   const [activeSection, setActiveSection] = useState('dashboard'); // State to track active section
   const navigate = useNavigate(); // React Router hook for navigation

   const handleVirtualIr = () => {
      // Show the loading screen
      setLoading(true);

      // After 2 seconds, navigate to the result page
      setTimeout(() => {
         setLoading(false); // Hide the loading screen
         navigate('/startuproom/mockir'); // Navigate to the result page
      }, 3000); // 2 seconds delay
   };

   useEffect(() => {
      const handleScroll = () => {
         const sections = document.querySelectorAll('.section');
         sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
               setActiveSection(section.getAttribute('data-section'));
            }
         });
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   if (loading) {
      return (
         <div className="loading-overlay">
            <div className="loading-content">
               <div className="loading-message">가상IR환경으로 이동중입니다...</div>
               <div className="loading-message">잠시만 기다려 주세요.</div>
               <div className="loading-icons">
                  <span className="loader-icon">⏳</span>
               </div>
            </div>
         </div>
      );
   }

   return (
      <div>
         {/* <div className="page-title">Private Page </div> */}
         <div className="private-navbar-wrapper">
            <div className="private-navbar">
               <ul className="private-navbar-menu">
                  {/* <li className="navbar-item">
                  <Link to="/" className="navbar-link" onClick={closeSidebar}>
                     Home
                  </Link>
               </li> */}
                  <li className="private-navbar-item-container">
                     <button to="#" className={`private-navbar-item ${activeSection === 'dashboard' ? 'active' : ''}`}>
                        대시보드
                     </button>
                     <button to="#" className={`private-navbar-item ${activeSection === 'companyInfo' ? 'active' : ''}`}>
                        기업정보
                     </button>
                     <button to="#" className={`private-navbar-item ${activeSection === 'investmentStatus' ? 'active' : ''}`}>
                        투자현황
                     </button>
                     <button to="#" className={`private-navbar-item ${activeSection === 'financialInfo' ? 'active' : ''}`}>
                        재무정보
                     </button>
                     <button to="#" className={`private-navbar-item ${activeSection === 'employmentStatus' ? 'active' : ''}`}>
                        고용현황
                     </button>
                     <button to="#" className={`private-navbar-item ${activeSection === 'industryComparison' ? 'active' : ''}`}>
                        산업비교
                     </button>
                     <button to="#" className={`private-navbar-item ${activeSection === 'mainInfo' ? 'active' : ''}`}>
                        주요정보
                     </button>
                     <Link className="private-navbar-item submit" onClick={handleVirtualIr}>
                        <button className="private-navbar-item">Virtual IR</button>
                     </Link>
                  </li>
               </ul>
            </div>
         </div>
         <div className="private-company-name">
            <img src={require('../data/foursales_logo.png')} alt="Placeholder 2" className="foursales-logo" />
            포세일즈
         </div>
         <div className="private-section section">
            <div className="section-title section" data-section="dashboard">
               대시보드
               <div className="section-3pillar-chart">
                  <div className="chart-3info-container">
                     <div className="chart-info-key">전월 대비 방문자 수 성장률</div>
                     <img src={require('../data/demo2/privatePage/1.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                  </div>
                  <div className="chart-3info-container">
                     <div className="chart-info-key">전월 대비 소비자 거래지수 성장률</div>
                     <img src={require('../data/demo2/privatePage/2.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                  </div>
                  <div className="chart-3info-container">
                     <div className="chart-info-key">전월 대비 고용인원 성장률</div>
                     <img src={require('../data/demo2/privatePage/3.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                  </div>
               </div>
            </div>
            <div className="section" data-section="companyInfo">
               <div className="section-title">기업정보</div>
               <div className="section-container">
                  <div className="section-content">전자가격표시기를 개발 및 제조하는 기업</div>
                  <div className="section-keyword">
                     <div className="keyword-container">
                        <div className="keyword">제조/하드웨어</div>
                        {/* <div className="keyword-count">2010</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">팁스(TIPS)2023</div>
                        {/* <div className="keyword-count">598</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">하드웨어</div>
                        {/* <div className="keyword-count">1023</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">소프트웨어</div>
                        {/* <div className="keyword-count">1674</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">소프트웨어</div>
                        {/* <div className="keyword-count">1674</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">제조기술</div>
                        {/* <div className="keyword-count">2047</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">B2B</div>
                        {/* <div className="keyword-count">5942</div> */}
                     </div>
                     <div className="keyword-container">
                        <div className="keyword">통신/네트워크/5G</div>
                        {/* <div className="keyword-count">151</div> */}
                     </div>
                  </div>
                  <div className="section-other-info">
                     <div className="other-info-container">
                        <div className="other-info-key">상장여부</div>
                        <div className="other-info-value">비상장</div>
                     </div>
                     <div className="other-info-container">
                        <div className="other-info-key">운영여부</div>
                        <div className="other-info-value">운영중</div>
                     </div>
                     <div className="other-info-container">
                        <div className="other-info-key">설립일자</div>
                        <div className="other-info-value">2017-10-01</div>
                     </div>
                     <div className="other-info-container">
                        <div className="other-info-key">홈페이지</div>
                        <div className="other-info-value">-</div>
                     </div>
                  </div>
                  <div className="address-container">
                     <div className="address-key">주소</div>
                     <div className="address-value">서울 서초구 남부순환로 2497, 708호 호서벤처대학원</div>
                  </div>
               </div>
            </div>

            <div className="section-title section" data-section="investmentStatus">
               투자현황
               <div className="section-1pillar-chart">
                  <div className="chart-1info-container">
                     {/* <div className="chart-info-key">손익</div> */}
                     <img src={require('../data/demo2/privatePage/9.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                  </div>
               </div>
               <div className="section-2pillar-chart">
                  <div className="chart-2info-container">
                     <div className="chart-info-key">투자 성장 현황</div>
                     <img src={require('../data/demo2/privatePage/4.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                  </div>
                  <div className="chart-2info-container">
                     <div className="chart-info-key">투자 유치 단계</div>
                     <img src={require('../data/demo2/privatePage/5.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                  </div>
               </div>
               <div className="section-title section" data-section="financialInfo">
                  재무정보
                  <div className="section-2pillar-chart">
                     <div className="chart-2info-container">
                        <div className="chart-info-key">손익</div>
                        <img src={require('../data/demo2/privatePage/6-1.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                        <img src={require('../data/demo2/privatePage/6-2.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                     </div>
                     <div className="chart-2info-container">
                        <div className="chart-info-key">재무</div>
                        <img src={require('../data/demo2/privatePage/7-1.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                        <img src={require('../data/demo2/privatePage/7-2.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                     </div>
                  </div>
               </div>
               <div className="section-title section" data-section="employmentStatus">
                  고용현황
                  <div className="section-1pillar-chart">
                     <div className="chart-1info-container">
                        {/* <div className="chart-info-key">손익</div> */}
                        <img src={require('../data/demo2/privatePage/8.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                     </div>
                  </div>
               </div>
               <div className="section-title section" data-section="industryComparison">
                  산업비교
                  <div className="section-2pillar-chart">
                     <div className="chart-2info-container">
                        {/* <div className="chart-info-key">손익</div> */}
                        <img src={require('../data/demo2/privatePage/11.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                     </div>
                     <div className="chart-2info-container">
                        {/* <div className="chart-info-key">재무</div> */}
                        <img src={require('../data/demo2/privatePage/12.PNG')} alt="Visitor Growth Chart" className="chart-info-chart" />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {/* <div className="section">
            <div className="section-title">주요정보</div>
            <div className="section-container">
               <div className="section-other-info">
                  <div className="other-info-container">
                     <div className="other-info-key">자본금</div>
                     <div className="other-info-value">6.3억원</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">고용인원</div>
                     <div className="other-info-value">9명</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">누적투자유치금액</div>
                     <div className="other-info-value">비공개</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">누적투자유치건수</div>
                     <div className="other-info-value">1</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">연매출</div>
                     <div className="other-info-value">3.7억원</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">기술등급</div>
                     <div className="other-info-value">2.0</div>
                  </div>
               </div>
            </div>
         </div> */}
      </div>
   );
};

export default FourSales;
