import React, { useState, useEffect, useRef } from 'react';
import IndustryFilter from './IndustryFilter';
import LocationFilter from './LocationFilter';
import FundingLevelFilter from './FundingLevelFilter';
// import StageFilter from './StageFilter';
import './FiltersBar.css';

function FiltersBar({ industryFilter, setIndustryFilter, locationFilter, setLocationFilter, fundingLevelFilter, setFundingLevelFilter }) {
   const [isSidebarOpen, setSidebarOpen] = useState(false);
   const sidebarRef = useRef(null);

   const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
   };

   const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
         setSidebarOpen(false);
      }
   };

   useEffect(() => {
      if (isSidebarOpen) {
         document.addEventListener('mousedown', handleClickOutside);
      } else {
         document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [isSidebarOpen]);

   return (
      <div>
         {/* Mobile Filter Button */}
         <button className="filter-button" onClick={toggleSidebar}>
            Filter
         </button>

         {/* Sidebar for Filters */}
         <div ref={sidebarRef} className={`filter-sidebar ${isSidebarOpen ? 'open' : ''}`}>
            <IndustryFilter industryFilter={industryFilter} setIndustryFilter={setIndustryFilter} />
            <LocationFilter locationFilter={locationFilter} setLocationFilter={setLocationFilter} />
            <FundingLevelFilter fundingLevelFilter={fundingLevelFilter} setFundingLevelFilter={setFundingLevelFilter} />
            {/* <StageFilter stageFilter={stageFilter} setStageFilter={setStageFilter} /> */}
         </div>
      </div>
   );
}

export default FiltersBar;
