import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './FinalPage.css';

const FinalPage = () => {
   const navigate = useNavigate(); // Initialize useNavigate

   const handleImageClick = () => {
      navigate('/'); // Navigate to the root path
   };

   return (
      <div className="final-page-container">
         <img
            src={require('../data/finalPage.png')}
            alt="Placeholder 2"
            className="final-page"
            onClick={handleImageClick} // Add onClick event to the image
            style={{ cursor: 'pointer' }} // Change cursor to pointer
         />
      </div>
   );
};

export default FinalPage;
