import React, { useState } from 'react';
import CompanyItem from './CompanyItem';
import FiltersBar from './Filters/FiltersBar';
import './CompanyList.css'; // Import the CSS file

function CompanyList({ companies }) {
   const [industryFilter, setIndustryFilter] = useState([]);
   const [fundingLevelFilter, setFundingLevelFilter] = useState([]);
   const [locationFilter, setLocationFilter] = useState([]);

   // Filtering logic
   const filteredCompanies = companies.filter((company) => {
      return (
         (industryFilter.length === 0 || industryFilter.includes(company.category)) &&
         (fundingLevelFilter.length === 0 || fundingLevelFilter.includes(company.funding_level)) &&
         (locationFilter.length === 0 || locationFilter.includes(company.location))
      );
   });

   return (
      <div>
         <FiltersBar
            industryFilter={industryFilter}
            setIndustryFilter={setIndustryFilter}
            locationFilter={locationFilter}
            setLocationFilter={setLocationFilter}
            fundingLevelFilter={fundingLevelFilter}
            setFundingLevelFilter={setFundingLevelFilter}
         />
         <ul className="companylist-item" style={{ listStyleType: 'none', padding: 0 }}>
            {filteredCompanies.map((company, index) => (
               <CompanyItem key={index} company={company} />
            ))}
         </ul>
      </div>
   );
}

export default CompanyList;
