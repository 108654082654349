import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Dataroom from './Dataroom';
import Chatbot from './ChatbotDemo2';
import './Demo.css'; // Make sure to create and import this CSS file

const Demo2 = ({ companies }) => {
   const location = useLocation(); // Get the current location
   const backgroundColor = location.pathname === '/startuproom/mockir' ? 'lightblue' : 'white'; // Change as desired

   return (
      <div style={{ backgroundColor }}>
         <div className={`demo-container2 ${location.pathname === '/startuproom/mockir' ? 'interview' : ''}`}>
            <div className={`dataroom-container2 ${location.pathname === '/startuproom/mockir' ? 'interview' : ''}`}>
               <Outlet companies={companies} />
            </div>
            {/* Conditionally render the Chatbot based on the current URL */}
            {location.pathname !== '/startuproom/mockir' && <Chatbot />}
         </div>
      </div>
   );
};

export default Demo2;
