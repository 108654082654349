import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import './MockIR';
import './MockIR.css';
import aiAvatar from '../data/demo2/ai-avatar.png';
import startUpCEO from '../data/demo2/start-up-ceo.png';
import loadingPage from '../data/demo2/loading_image.png';

// import axios from 'axios';
const MockIR = () => {
   const [loading, setLoading] = useState(false); // State to manage loading screen
   const aiRef = useRef(null); // Ref for the ai video
   const humanRef = useRef(null); // Ref for the human video
   const navigate = useNavigate(); // React Router hook for navigation

   const handleEndClick = () => {
      // Show the loading screen
      setLoading(true);

      // After 2 seconds, navigate to the result page
      setTimeout(() => {
         setLoading(false); // Hide the loading screen
         navigate('/startuproom/mockirResult'); // Navigate to the result page
      }, 3000); // 2 seconds delay
   };

   const handleAiSpeak = () => {
      if (aiRef.current) {
         if (aiRef.current.paused) {
            aiRef.current.muted = false; // Ensure audio is not muted
            setTimeout(() => {
               aiRef.current.play(); // Play the human video
            }, 700); // video will play 0.7 secs after click
         } else {
            aiRef.current.pause(); // Pause the human video if it's playing
            aiRef.current.currentTime = 0; // Reset to the beginning
         }
      }

      // Stop the AI video if playing
      if (humanRef.current) {
         humanRef.current.pause(); // Pause the AI video if it's playing
         humanRef.current.currentTime = 0; // Reset to the beginning
      }
   };

   const handleHumanSpeak = () => {
      if (humanRef.current) {
         if (humanRef.current.paused) {
            humanRef.current.muted = false; // Ensure audio is not muted
            setTimeout(() => {
               humanRef.current.play(); // Play the human video
            }, 700); // video will play 0.7 secs after click
         } else {
            humanRef.current.pause(); // Pause the human video if it's playing
            humanRef.current.currentTime = 0; // Reset to the beginning
         }
      }

      // Stop the AI video if playing
      if (aiRef.current) {
         aiRef.current.pause(); // Pause the AI video if it's playing
         aiRef.current.currentTime = 0; // Reset to the beginning
      }
   };

   if (loading) {
      return (
         <video className="mock-ir-wrapper" autoPlay muted loop>
            <source src={require('../data/demo2/LoadingVideo.webm')} className="mock-ir-container" type="video/webm" />
            Your browser does not support the video tag.
         </video>
      );
   }

   return (
      <div className="mock-ir-wrapper">
         <div className="mock-ir-container">
            <div className="mock-ir">
               <div className="interviewer-container">
                  <div className="screen-title"> AI 투자심사역</div>
                  <div className="interviewer-screen">
                     {/* <video ref={aiRef} className="interviewer" muted loop> */}
                     <video ref={aiRef} className="interviewer">
                        <source src={require('../data/demo2/ai-speaks.mp4')} type="video/mp4" />
                        Your browser does not support the video tag.
                     </video>
                     <div></div>
                  </div>
               </div>

               <div className="interviewee-container">
                  <div className="screen-title"> You are shown here.</div>
                  <div className="interviewee-screen">
                     {/* <video ref={humanRef} className="interviewee" muted loop> */}
                     <video ref={humanRef} className="interviewee" muted>
                        <source src={require('../data/demo2/human-speaks.mp4')} type="video/mp4" />
                        Your browser does not support the video tag.
                     </video>
                  </div>
               </div>
            </div>
            <div className="settings">
               <div className="mockir-button-container">
                  <button className="mockir-button">자료제출</button>

                  <button className="mockir-button" onClick={handleAiSpeak}>
                     가상IR시작
                  </button>
                  <button className="mockir-button" onClick={handleHumanSpeak}>
                     답변시작
                  </button>
                  <Link className="mockir-button submit" onClick={handleEndClick}>
                     결과분석
                  </Link>
               </div>
               {/* <div className="mockir-button-container">
                     <div className="mockir-button placeholder"></div>
                     <div className="mockir-button placeholder"></div>
                     <div className="mockir-button placeholder"></div>

                     <Link className="mockir-button submit" onClick={handleEndClick}>
                        종료 & 결과분석
                     </Link>
                  </div> */}
            </div>
         </div>
      </div>
   );
};

export default MockIR;
