import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import MainContent from './MainContent'; // Import the new component
import './App.css';

function App() {
   const [isAuthenticated, setIsAuthenticated] = useState(false);

   useEffect(() => {
      let timeout;
      if (isAuthenticated) {
         timeout = setTimeout(() => {
            setIsAuthenticated(false);
            alert('You have been signed out due to inactivity.');
         }, 1000 * 60 * 60 * 12); // 12 hours
      }
      return () => clearTimeout(timeout);
   }, [isAuthenticated]);

   const handleSignIn = () => {
      setIsAuthenticated(true);
   };

   const handleSignOut = () => {
      setIsAuthenticated(false);
   };

   return (
      <Router>
         <Navbar isAuthenticated={isAuthenticated} handleSignOut={handleSignOut} />
         <MainContent handleSignIn={handleSignIn} /> {/* Use MainContent here */}
         {/* <Footer /> */}
      </Router>
   );
}

export default App;
