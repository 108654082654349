import React from 'react';
import theme from '../theme';

function Footer() {
   return (
      <footer
         style={{
            backgroundColor: theme.primaryColor,
            color: '#fff',
            textAlign: 'center',
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            zIndex: 1000,
            height: '30px' /* Explicit height for smaller footer */,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
         }}
      >
         <div style={{ margin: 0, padding: 0 }}>
            <p
               style={{
                  color: '#fff',
                  margin: 0,
                  padding: 0,
                  fontSize: '14px' /* Smaller font size */,
                  lineHeight: '1' /* Minimized line height */,
               }}
            >
               &copy; 2024 AI�4�All | All rights Reserved.
            </p>
         </div>
      </footer>
   );
}

export default Footer;
