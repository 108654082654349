const openAIInput = `
[지시문]
아래는 스타트업을 위한 [IR 발표자료 작성법]이다. 내가 스타트업 아이디어를 작성하면 IR 발표자료를 작성해주는데 꼭 필요한 정보가 누락된 경우, 바로 작성하지말고 그 정보에 대해서 물어봐줘.

[IR 발표자료 작성법]
IR은 Investor Relations의 약자입니다. 투자자와 관계를 맺고 유지하는 데 필요한 모든 자료와 활동을 일컫는 표현이죠. IR자료란, 투자유치를 위한 활동을 지원하기 위해 활용 되는 자료를 뜻합니다. 기업이 투자자에게 소개하는 자료를 주로 IR자라고 표현하고 있습니다.  사업을 할 때 많은 소개서를 작성하게 되는데요, 특히 많은 창업가들이 IR자료 작성에서 어려움을 겪고 있습니다. IR자료를 작성해본 경험이 없을뿐더러, IR자료의 경우 많은 기업에서 대외비로 취급해 참고할 수 있는 자료를 찾기 어렵기 때문입니다. 그럼 IR자료와 다른 소개서의 차이점은 무엇일까요?
□ IR자료와 소개서의 차이점
IR자료와 다른 소개서의 가장 큰 차이점은 주제입니다. 매체 소개서, 서비스 소개서 등 일반적인 소개서는 주로 서비스에 집중된 내용인 반면, IR자료는 사업에 집중해서 내용을 구성해야 합니다. 먼저 서비스에 집중된 소개서는 서비스 요약, 서비스 작동방식, 서비스 비용, 문의처 등이 필수적인 요소입니다. 반면 IR자료에서는 서비스를 넘어 서비스 요약, 시장 환경, 경쟁 환경, 서비스 성과, 비즈니스 모델, 성장 계획, 팀 등 사업 전반적인 내용을 포함해야 합니다. 그럼 각각의 항목을 IR자료에 어떻게 포함해야 할까요?
□ 서비스 요약
서비스 요약은 대표적으로 ‘문제’, ‘솔루션 및 서비스 소개’ 2가지로 설명할 수 있습니다. ‘문제’에서는 해당 서비스가 시중에 어떤 문제를 해결하고자 하는지, 소비자들이 이 문제에 대해 얼마나 불편해하는지, 해결에 대한 니즈가 있는지, 지금 당장 이 문제를 해결해야 할 문제인지의 관점에서 기술하는 것이 좋습니다.  이후 솔루션을 소개한다면 어떤 문제를 어떻게 해결하려 하는지 쉽게 설명할 수 있습니다. 여기서 중요한 점은 앞서 언급한 문제를 어떻게 직접적으로 해결할 것인지, 기존 방식 대비 소비자 및 엔드유저가 얻는 장점이 무엇인지 명확하게 설명할 수 있어야 합니다. 투자자들이 해당 분야에 대한 전문가가 아닌 만큼, 소비자 입장에서 서비스를 설명하는 것이 필연적입니다. 기술적인 부분은 추후 질문이 들어오면 개별적으로 대응하는 것을 추천합니다.
□ 시장 규모
서비스 요약이 끝났다면, 다음으로는 해당 서비스가 활동할 시장규모를 설명해야 합니다. 시장 규모를 돈이 아닌 지표로 설정할 수도 있는데요. 각 요소의 지표를 비중으로 비교할 수 있습니다. 규모를 금액으로 설정할 경우, 다양한 이슈가 발생할 수 있습니다. 예를 들어 귀사의 솔루션이 시중 솔루션보다 저렴하다면 시장 규모는 훨씬 줄어들 수 있죠. 이에 영향을 받지 않는 수치로 설정함으로써 투자자들 사이에서 이견도 줄일 뿐만 아니라, 효과적으로 시장 규모를 알릴 수 있습니다.  TAM(전체시장), SAM(유효시장), SOM(수익시장) - 처음부터 시장 전체를 장악하는 기업은 없습니다. 순차적으로 어떤 시장부터 공략할 것인지, 3단계로 나눠서 생각하는 것이 좋습니다.
□ 경쟁 환경
여기서 중요한 것은 고객 입장에서 서술하고, 주요 경쟁자를 명시해야 한다는 점입니다. 고객 입장에서 타사 서비스 및 제품과 비교해 당사 서비스 및 제품이 어떤 면에서 뛰어나고 장점이 있는지 작성해야 합니다. 그리고 어떤 서비스를 경쟁 서비스로 정하고 어떤 고객을 주요 타깃으로 할지 명시해야 합니다. 경쟁자를 제대로 파악하고 있는지에 대한 여부에 따라 투자자들에게 시장현황을 잘 파악하고 있다는 점을 어필할 수 있습니다.
□ 서비스 성과
서비스 혹은 제품이란 가설을 시중에서 테스트하고, 어떤 성과가 있었는지 보여주는 영역입니다. 가설의 성과와 미래의 성장성을 비춰주도록 작성하는 것이 좋습니다.  무엇보다 성과를 사업 핵심지표로 보여주는 것이 중요합니다. 먼저 사업의 성공에 있어 어떤 지표가 가장 중요한지 파악해야 하며, 그 지표를 중심으로 성과를 설명해야 합니다. SaaS 기업은 월간 구독 거래 대금을 가장 중요한 지표로 설정하고, 해당 지표의 성장곡선을 설명하고 있습니다. 특히 여기서 핵심이 아닌 부수적인 지표는 제외하는 것이 좋습니다.  SaaS 로딩 속도, 혹은 지원 언어수 등 사업의 성공과 직결되지 않는 지표에 대해서는 배제하여 설명하고 있듯이, 성과 영역에서는 핵심 지표 외의 지표들은 포함하지 않는 것이 좋습니다.
□ 핵심 지표란?
핵심 지표는 서비스 및 사업의 성공에 가장 큰 영향을 주는 지표를 말합니다. 예를 들어 이커머스 서비스의 ‘주문 건수’, 소셜미디어의 ‘월간 활성 사용자(MAU)’, 카드사의 ‘월간 거래대금’ 등이 대표적입니다. 이들의 지표만 봐도 해당 서비스가 사업적으로 성장하고 있는지 또는 문제가 있는지 파악이 가능하죠.
□ 비즈니스 모델
비즈니스 모델은 쉽게 말해 회사가 돈을 어떻게 벌지에 대해 설명하면 됩니다.
누가 돈을 지불하게 되나? - 때로는 엔드유저와 고객이 일치하지 않을 때가 있죠. 혼선을 방지하기 위해서는 비즈니스 모델에서 실질적인 매출을 발생시킬 수 있는 고객이 누군지 명확하게 해주는 것이 좋습니다.
고객이 돈을 지불하게 되는 이유가 무엇인가? – 서비스 및 제품 중 어떤 부분을 어떻게 무료로 제공하고 어떤 것들은 과금을 하는지, 이에 대해 명확하게 설명해주면 어떻게 돈을 벌고자 하는지 더 명확히 설명할 수 있습니다.
□ 성장 계획
좋은 선례가 없다면, 반대로 시기별 달성하고자 하는 목표 위주로 서술해주는 것도 좋은 방법입니다. 투자 후 마일스톤을 언제 달성할지 대략적으로 보여줄 수 있습니다. 분기별 달성하고자 하는 마일스톤을 서술하고 앞으로 투자받고 어떤 것들을 달성하고자 하는지 설명한다면, 자금적 목표와 성과 목표 대조가 가능하며 방향성을 이해하는 데도 큰 도움이 됩니다. 특히 마일스톤은 다음 투자 단계 전까지 설정하는 것이 좋은데, 시드라면 시리즈A 전까지, 시리즈A라면 B 전까지의 성장 계획을 어떻게 이어갈 것인지 보여주면 됩니다.
□ 팀
IR자료에서 가장 중요한 요소 중 하나가 바로 팀이라고 생각합니다. 결국 사람이 하는 일인 만큼, 팀에 대한 평가는 불가피한 요소 중 하나입니다. 여기서 추천드릴 만한 요소를 소개하고자 합니다.
첫째, 주요 팀원들 위주로 설명하는 것이 좋습니다. 모두를 소개하기에는 자료가 너무 길어지죠. 사업에서 핵심적인 역할과, 그 역할을 수행하는 팀원 위주로 작성하는 것이 좋습니다.
둘째, 경력 기술은 로고를 활용합니다. 주요 팀원들이 왜 해당 직책에 적합한지 증명하기 위해서는 경력을 필수적으로 기술해야 합니다. 가장 적합한 경력을 2개 내외로 정하고, 기업 또는 서비스 로고로 노출하면 한눈에 이해하기 쉬운 자료가 될 수 있습니다.
셋째, 투자자와 고문들도 팀입니다. 특히 초기 기업에게는 누가 투자했고, 누가 고문으로 참여하고 있는지가 비즈니스의 성공가능성을 예측하는 데 막대한 영향을 미칩니다. 핵심적인 역할을 해준다면 포함하는 것도 좋습니다.
□ IR자료 작성하기
지금까지 IR자료 작성하는 방법에 대해 설명드렸습니다. 안내한 내용들을 참고하면서 IR자료를 작성한다면 조금 더 쉽고, 좋은 자료를 만들 수 있을 거라 생각합니다.  하지만 모든 비즈니스에서 정답은 없듯이 IR작성에서도 100%의 정답은 없습니다. 모든 내용을 포함할 필요도 없으며, 이외 내용을 완전히 배제할 필요도 없습니다. 핵심은 귀사의 회사 및 사업을 소개하는 데 도움이 되는 방향으로 최대한 활용하면 된다는 것입니다. 투자자는 해당 서비스 및 사업에 대해 처음 접했을 가능성이 높은 만큼, 이해에 방해되는 부수적인 내용을 최대한 제거해야 합니다. 시장에서 왜 해당 서비스 및 사업이 성공할 수 있고, 성공시킬 수 있는 것이 왜 우리인지를 핵심에 두고 작성하는 것이 중요합니다.
`;

export default openAIInput;
