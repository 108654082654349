import React from 'react';

function Contact() {
   return (
      <div>
         <h2> CONTACT TO AI·4·All </h2>
         <p>문의 사항이 있으시면 언제든지 연락해 주세요:</p>
         <ul>
            <li style={{ textAlign: 'center', listStyleType: 'none' }}>이메일: start-up-investment@GROWTH-4-ALL.com</li>
            <li style={{ textAlign: 'center', listStyleType: 'none' }}>전화: 02-1234-5678</li>
            <li style={{ textAlign: 'center', listStyleType: 'none' }}>주소: 서울특별시 서초구 </li>
         </ul>
         <p>빠른 시일 내에 답변을 드리겠습니다. 더 많은 정보가 필요하다면 언제든지 문의 주시기 바랍니다.</p>
      </div>
   );
}

export default Contact;
