import React from 'react';

const Ilman = () => {
   return (
      <div>
         <h1>Ilman Company Details</h1>
         <p>Here are the details for Ilman...</p>
      </div>
   );
};

export default Ilman;
