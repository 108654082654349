import React from 'react';
import { Outlet } from 'react-router-dom';
import Dataroom from './Dataroom';
import Chatbot from './ChatbotDemo1';
import './Demo.css'; // Make sure to create and import this CSS file

const Demo = ({ companies }) => {
   return (
      <div>
         {/* <div className="page-title"> 데이터룸 </div> */}
         <div className="demo-container">
            {/* Left-hand side: Dataroom */}
            <div className="dataroom-container">
               <Outlet companies={companies} />
            </div>

            {/* Right-hand side: Chatbot */}

            <Chatbot />
            {/* <Chatbot className="chatbot-container" /> */}
         </div>
      </div>
   );
};

export default Demo;
