export const conclusion = `지금까지 IR자료 작성하는 방법에 대해 설명드렸습니다. 안내한 내용들을 참고하면서 IR자료를 작성한다면 조금 더 쉽고, 좋은 자료를 만들 수 있을 거라 생각합니다.  하지만 모든 비즈니스에서 정답은 없듯이 IR작성에서도 100%의 정답은 없습니다. 모든 내용을 포함할 필요도 없으며, 이외 내용을 완전히 배제할 필요도 없습니다. 핵심은 귀사의 회사 및 사업을 소개하는 데 도움이 되는 방향으로 최대한 활용하면 된다는 것입니다. 투자자는 해당 서비스 및 사업에 대해 처음 접했을 가능성이 높은 만큼, 이해에 방해되는 부수적인 내용을 최대한 제거해야 합니다. 시장에서 왜 해당 서비스 및 사업이 성공할 수 있고, 성공시킬 수 있는 것이 왜 우리인지를 핵심에 두고 작성하는 것이 중요합니다.`;
export const conclusionNegative = `소프트프릭은 자산이 2022년에서 2023년 사이 크게 증가했지만, 부채 또한 크게 증가한 상태입니다. 재무적으로 자본이 강화된 것은 긍정적이지만, 부채가 급증한 상황에서 향후 재무 건전성에 대한 우려가 있으며, 추가 자본 투자가 필요할 것으로 보입니다.`;
export const conclusionPositive = `소프트프릭의 자산과 자본이 2022년 대비 2023년에 크게 증가하여, 성장 가능성을 보여주고 있습니다. 또한, 자본이 강화된 상황에서 지속적인 기술 개발과 글로벌 시장 확장이 기대됩니다. 향후 자본을 활용한 적극적인 투자가 이루어진다면, 경쟁력 있는 기업으로 발전할 가능성이 큽니다.산업분석기술분석재무분석클라우드 보안 시장은 빠르게 성장하고 있으며, 소프트프릭은 시장 내에서 보안 수요에 맞춘 포괄적 솔루션을 제공하고 있음AI 기반 보안 솔루션과 클라우드 기술을 통합하여 효율성을 높였으며, 지속적인 기술 발전 가능성이 큼자산과 자본이 2023년에 크게 증가하여 안정적인 재무 기반을 마련했으며, 추가적인 성장 가능성이 높음`;

export const industryAnalysisNegative = `클라우드 보안 시장은 성장 중이지만 경쟁이 치열하며, 소프트프릭은 차별화된 강점을 확보하기 어려움`;
export const techAnalysisNegative = `AI 기반 취약점 진단 기술을 보유하나, 경쟁사와의 차별성이 부족하고 기술적 우위 확보가 어려움`;
export const financialAnalysisNegative = `자산은 증가했지만 부채도 크게 늘어 자본에 대한 리스크가 존재하며, 추가적인 재무 안정화가 필요함`;

export const industryAnalysisPositive = `클라우드 보안 시장은 빠르게 성장하고 있으며, 소프트프릭은 시장 내에서 보안 수요에 맞춘 포괄적 솔루션을 제공하고 있음`;
export const techAnalysisPositive = `AI 기반 보안 솔루션과 클라우드 기술을 통합하여 효율성을 높였으며, 지속적인 기술 발전 가능성이 큼`;
export const financialAnalysisPositive = `자산과 자본이 2023년에 크게 증가하여 안정적인 재무 기반을 마련했으며, 추가적인 성장 가능성이 높음`;

export const conclusionPositiveMarkdown = `사이클로이드는 중고 이륜차 거래 플랫폼인 '바드림'을 통해 시장 진입에 성공했습니다. 중고 이륜차 시장의 유동성이 크고, 약 1.9조 원 규모의 시장에서 잠재적인 성장 가능성이 매우 높습니다. 전기 이륜차의 보급이 증가함에 따라, 사이클로이드의 플랫폼은 사용자에게 더욱 매력적인 솔루션이 될 것입니다. 특히, 검증 시스템을 도입하여 허위 매물과 정보 비대칭성 문제를 해결하고, 안전한 거래를 제공함으로써 시장에서 차별화된 경쟁력을 지닙니다.

### 산업분석
중고 이륜차 시장은 현재 활성화되어 있으며, 연간 약 37%의 거래량이 발생하는 유동적인 시장입니다. 사이클로이드의 진출은 이와 같은 시장의 특성을 활용하여 빠르게 성장할 수 있는 기반이 됩니다. 특히, 이륜차 구매의 특성상 보유 기간이 짧아 중고 거래가 증가하고 있으며, 이는 사이클로이드의 기회로 작용할 것입니다. 전기 이륜차의 확산이 이루어질 경우, 이는 추가적인 성장 동력이 될 것으로 보입니다.
### 기술분석
사이클로이드는 검증 시스템과 전문가 연계를 통해 거래의 안전성을 높이는 기술적 접근을 가지고 있습니다. 이러한 혁신적인 기술은 소비자들에게 신뢰를 줄 수 있으며, 중고 거래의 고질적인 문제들을 해결하는 데 효과적일 것입니다. 또한, 플랫폼의 기술적 우수성은 사용자의 편의성을 극대화할 수 있는 기반이 될 것입니다. 이는 경쟁사와의 차별점으로 작용하여 시장에서 유리한 위치를 점할 수 있게 합니다.
### 재무분석
사이클로이드는 전기 이륜차 보급 확대와 함께 매출 증대 가능성이 높습니다. 초기 투자금 약 1억 원이 성공적으로 사용될 경우, 향후 매출 성장에 긍정적인 영향을 미칠 것으로 예상됩니다. 계약 확정으로 인해 매출이 증가할 가능성이 높고, 베트남 시장 진출은 추가적인 수익 창출 기회를 제공합니다. 이러한 잠재적인 수익 구조는 투자자들에게 매력적으로 다가올 수 있습니다.`;

export const conclusionNegativeMarkdown = `그러나, 시장에는 중고차 거래 플랫폼인 '중고나라'와 같은 기존의 강력한 경쟁자가 존재합니다. 사이클로이드가 제시하는 솔루션이 기존 경쟁자보다 얼마나 빠르게 또는 효과적으로 시장에서 자리잡을 수 있을지는 불확실합니다. 또한, 창업자의 경험에도 불구하고 시장의 신뢰를 확보하는 데 시간이 필요할 수 있습니다. 전기 이륜차의 보급이 늦어질 경우, 매출 성장이 예상보다 더딜 수 있는 리스크가 존재합니다.

### 산업분석
하지만 이 시장은 정부 규제와 공인 전문 정비사의 부족으로 인한 문제들이 존재합니다. 이러한 문제들은 소비자들이 중고 거래를 꺼리게 만들고 있으며, 사이클로이드가 직면할 수 있는 주요 장애물입니다. 게다가 허위 매물과 사기 매물 문제는 소비자 신뢰를 떨어뜨리며, 시장 성장에 부정적인 영향을 미칠 수 있습니다. 사이클로이드가 이러한 문제들을 해결하지 못할 경우, 시장에서의 경쟁력이 약화될 수 있습니다.
### 기술분석
반면, 사이클로이드의 기술이 실제로 소비자에게 얼마나 효과적으로 전달될지는 미지수입니다. 초기 플랫폼 설계와 운영 과정에서 기술적 문제가 발생할 경우, 소비자 불만과 시장 신뢰도 하락으로 이어질 수 있습니다. 또한, 경쟁사의 기술 발전 속도가 빨라지면, 사이클로이드의 기술적 우위가 약화될 가능성도 존재합니다. 이와 같은 기술적 리스크는 주의 깊게 관리해야 할 요소입니다.
### 재무분석
그러나 초기 매출 구조가 불확실하다는 점은 우려사항입니다. 시장 진입 초기에는 소비자 반응이 불투명하며, 수익 모델의 안정성을 확보하기 위한 시간이 필요할 수 있습니다. 추가적으로, 베트남 진출에 따른 초기 투자 비용이 예상보다 높을 경우, 자금 유동성 문제가 발생할 수 있습니다. 이러한 재무적 리스크는 투자자들에게 신뢰를 주기 위해 반드시 해결해야 할 사항입니다.`;

// export default conclusion;

export const conclusionPlusOverall = ` 포세일즈는 전자정보표시기(ESL) 시장에서 기술적 우위를 점하고 있으며, 종이라벨을 대체하는 혁신적인 솔루션을 제공하고 있습니다. 글로벌 시장에서 환경 규제가 강화되고 ESG 경영이 중요해지면서 이 회사의 제품은 필수적인 수요를 창출할 것입니다. 특히 일본, 인도네시아 등의 시장 진출을 통해 빠르게 성장할 가능성이 높습니다. 다양한 특허와 기술 혁신을 통해 경쟁력을 확보하고 있어 장기적으로 안정적인 성장이 예상됩니다.`;
export const conclusionPlusIndustry = `ESL 시장은 유럽, 북미, 아시아 등에서 지속적으로 성장하고 있으며, 포세일즈는 이를 활용해 빠르게 시장 점유율을 확보할 기회를 가지고 있습니다. 특히 종이라벨을 대체하는 ESL 제품은 ESG 트렌드와 맞물려 기업들의 도입이 가속화될 것입니다. 일본, 인도네시아 등 주요 시장에서의 POC 진행 상황은 향후 본격적인 매출 성장에 긍정적인 영향을 미칠 것입니다. 시장 확장은 유통업, 병원, 물류센터 등 다양한 분야로 확대될 가능성이 큽니다.`;
export const conclusionPlusTechnical = `포세일즈는 경쟁사 대비 빠른 업데이트 속도(1~2분), 긴 배터리 수명(12년), 완전 방수 기능을 제공하며, RGB LED 색상 표현, POP 홀더 등의 차별화된 기능으로 기술적 우위를 점하고 있습니다. 자체 개발한 소프트웨어와 하드웨어를 통해 더 나은 성능과 효율성을 제공하고 있으며, 이러한 기술력은 고객들에게 큰 매력으로 작용할 것입니다. 또한 다양한 기능을 추가로 개발해 매장 운영 효율성을 극대화할 수 있습니다.`;
export const conclusionPlusFinancial = `포세일즈는 2024년부터 본격적인 매출 성장이 예상되며, 국내외에서 빠르게 성과를 거둘 수 있을 것입니다. 특히 일본과 인도네시아 시장에서의 성공적인 POC는 향후 계약 체결과 매출 증대에 긍정적인 신호로 작용합니다. 향후 2025년 이후로는 매년 2배 이상의 성장을 목표로 하고 있으며, 지속적인 수익 증대와 더불어 안정적인 재무 구조를 확보할 것으로 기대됩니다.`;
export const conclusionMinusOverall = `포세일즈는 기술력에서 강점을 가지고 있지만, 시장 내 주요 경쟁사들이 이미 상당한 점유율을 확보하고 있는 상황입니다. ESL 시장은 경쟁이 치열해지면서 가격 경쟁력과 서비스 효율성에서의 추가적인 개선이 필요할 수 있습니다. 시장 침투 속도가 예측보다 느려질 경우, 매출 성장에 제동이 걸릴 가능성이 있습니다. 특히 초기 자금 조달과 운영비용 관리에서 리스크가 존재합니다.`;
export const conclusionMinusIndustry = `아시아, 북미 등에서 ESL 도입이 초기 단계에 머물러 있어 시장 성장이 예상보다 더디게 진행될 수 있습니다. 시장 내 주요 경쟁사들이 이미 유럽과 북미 지역에서 큰 점유율을 차지하고 있어 포세일즈의 진입 장벽이 높을 수 있습니다. 특히 경쟁사 대비 가격 경쟁력에서 부족할 경우, 시장 내에서 우위를 점하는 데 어려움을 겪을 수 있습니다. 각국의 규제 변화 및 경쟁사들의 기술 개발 속도에 맞춘 대응이 필요합니다.`;
export const conclusionMinusTechnical = `포세일즈의 기술력은 뛰어나지만, 특히 중앙 서버 관리와 관련된 기술적 문제에서 경쟁사 대비 개선이 필요할 수 있습니다. 기술적 우위를 점하고 있지만, 실제 매장에 적용되는 과정에서 예기치 않은 기술적 리스크가 발생할 가능성이 존재합니다. 경쟁사들이 유사한 기술을 빠르게 도입하고 확산할 경우, 포세일즈의 경쟁 우위가 약화될 수 있습니다.`;
export const conclusionMinusFinancial = `초기 자금 조달과 운영비용 관리가 중요한 과제로 남아 있으며, 특히 대규모 시장 진출을 위한 추가 자본 투자가 필요할 수 있습니다. 현재 매출 성장은 예상되지만, 예상보다 시장 도입이 지연되거나 경쟁사의 강력한 마케팅으로 인해 이익률이 떨어질 가능성도 있습니다. 현금 흐름 관리와 투자 대비 수익률의 지속적인 개선이 필요합니다.`;
