import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

function SignIn({ onSignIn }) {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const navigate = useNavigate();

   const handleSignIn = (e) => {
      e.preventDefault();
      // Accept any credentials for sign-in
      onSignIn();
      navigate('/'); // Redirect to home page or any other page
   };

   return (
      <div className="auth-container">
         <h2>로그인</h2>
         <form onSubmit={handleSignIn}>
            <div className="form-group">
               <label>Email</label>
               <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-group">
               <label>비밀번호</label>
               <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button type="submit" className="auth-button">
               로그인
            </button>
         </form>
         <p>
            계정이 없으신가요? <a href="/signup">회원가입</a>
         </p>
      </div>
   );
}

export default SignIn;
