import React from 'react';
import './FourSales.css';

// import axios from 'axios';
const FourSales = () => {
   return (
      <div>
         <div className="private-company-name">
            <img src={require('../data/foursales_logo.png')} alt="Placeholder 2" className="foursales-logo" />
            포세일즈
         </div>
         <div className="section">
            <div className="section-title">기업정보</div>
            <div className="section-container">
               <div className="section-content">전자가격표시기를 개발 및 제조하는 기업</div>
               <div className="section-keyword">
                  <div className="keyword-container">
                     <div className="keyword">제조/하드웨어</div>
                     {/* <div className="keyword-count">2010</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">팁스(TIPS)2023</div>
                     {/* <div className="keyword-count">598</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">하드웨어</div>
                     {/* <div className="keyword-count">1023</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">소프트웨어</div>
                     {/* <div className="keyword-count">1674</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">소프트웨어</div>
                     {/* <div className="keyword-count">1674</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">제조기술</div>
                     {/* <div className="keyword-count">2047</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">B2B</div>
                     {/* <div className="keyword-count">5942</div> */}
                  </div>
                  <div className="keyword-container">
                     <div className="keyword">통신/네트워크/5G</div>
                     {/* <div className="keyword-count">151</div> */}
                  </div>
               </div>
               <div className="section-other-info">
                  <div className="other-info-container">
                     <div className="other-info-key">상장여부</div>
                     <div className="other-info-value">비상장</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">운영여부</div>
                     <div className="other-info-value">운영중</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">설립일자</div>
                     <div className="other-info-value">2017-10-01</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">홈페이지</div>
                     <div className="other-info-value">-</div>
                  </div>
               </div>
               <div className="address-container">
                  <div className="address-key">주소</div>
                  <div className="address-value">서울 서초구 남부순환로 2497, 708호 호서벤처대학원</div>
               </div>
            </div>
         </div>

         {/* <div className="section">
            <div className="section-title">주요정보</div>
            <div className="section-container">
               <div className="section-other-info">
                  <div className="other-info-container">
                     <div className="other-info-key">자본금</div>
                     <div className="other-info-value">6.3억원</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">고용인원</div>
                     <div className="other-info-value">9명</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">누적투자유치금액</div>
                     <div className="other-info-value">비공개</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">누적투자유치건수</div>
                     <div className="other-info-value">1</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">연매출</div>
                     <div className="other-info-value">3.7억원</div>
                  </div>
                  <div className="other-info-container">
                     <div className="other-info-key">기술등급</div>
                     <div className="other-info-value">2.0</div>
                  </div>
               </div>
            </div>
         </div> */}

         {/* 




기업비교
포세일즈
VS
비교할 기업을 추가하고 그래프로 확인해 보세요
+ 추가
고용인원
9명
비교 기업을 추가해 보세요
2024-08
연매출
단위: 원
3.7억2023
월 방문자 수
-
최근투자유치
단위: 원
비공개2023-09
누적투자유치
단위: 원
비공개
소비자 거래지수
단위: 배
-
유사기업
- 해당 기업과 카테고리, 태그 등의 유사도가 높은 기업입니다.
- ‘추가’ 버튼을 클릭하면 해당 기업을 기업비교 리스트에 추가할 수 있고, ‘x’ 버튼을 클릭하면 추가한 기업을 삭제할 수 있습니다.
logo
인투코어테크놀로지
플라즈마 기술을 이용한 유해 가스 분해 기술을 개발하는 기업

추가
기술특허
업데이트 : 2024-03-20
보유특허 수
기업이 보유한 등록 특허수입니다.
4
특허기술 평균등급
기업이 보유한 등록 특허의 평균 기술등급입니다.
2.0
JS chart by amCharts
보유특허 수
* 특허의 기술 등급은 동종분야의 특허와 상대 비교를 통해 AA등급부터 C등급까지 총 8개 등급으로 산정되었습니다. (등급별 점수 AA:9점 ~ C:2점)
* 특허기술 평균 등급 산정 방식은 (등급별 평가 점수 * 등급별 특허 개수)의 총합 ÷ 보유 특허 수입니다.
* 막대 그래프는 등급별 특허 수입니다.
* 출처:
위즈도메인 위즈도메인
기술키워드
업데이트 : 2024-03-20
* 글씨의 크기가 클수록 많이 언급된 기술키워드입니다.
보유특허
업데이트 : 2024-03-20

데이터제보/오류신고
C
잠금장치 구동 방법(Method for operating lock device)
출원일 2020-10-06
C
스마트 카트(SMART CART)
출원일 2020-09-29
C
금융 카드 장치 및 금융 카드 장치를 이용한 포인트 적립 방법(Finance Card Apparatus and Method for Accumulating Point using Finance Card Apparatus)
출원일 2019-03-15
C
RFID 리더 장치 및 서버 장치(RFID Reader Apparatus and Server Apparatus)
출원일 2019-01-31
* 특허의 기술 등급은 동종분야의 특허와 상대 비교를 통해 AA등급부터 C등급까지 총 8개 등급으로 산정되었습니다. (등급별 점수 AA:9점 ~ C:2점)
* 특허 전문 분석기업의 AI 기술등급평가를 진행한 특허만 노출되며, 해당 기업이 보유한 특허 중 일부가 노출되지 않을 수 있습니다.
* 특허는 출원일 기준 가장 최신의 출원일 특허부터 최대 30개까지 노출됩니다.
* 출처:
위즈도메인 위즈도메인
투자유치
업데이트 : 2024-03-20

데이터제보/오류신고
최종투자단계
비공개
누적투자유치금액
비공개
투자유치건수
1
날짜	투자단계	투자유치금액	투자사
2023-09	비공개	비공개	인포뱅크
2023-09	지원금	비공개	팁스(TIPS)
* 보도자료, 기업 홈페이지 등을 통해 확인된 정보로 실제 기업의 투자이력과 다를 수 있습니다. (기업 및 투자사가 직접 제보한 투자이력이 포함될 수 있습니다.)
* 개별 투자유치금액이 비공개이더라도 누적투자유치 금액이 확인되는 경우, 누적투자유치 금액과 표에 기재된 투자유치금액의 합계가 다를 수 있습니다.
조직 분석 - 고용현황
업데이트 : 2024-09-24

데이터제보/오류신고
전체 인원수
9명
8월 입사자 수
0명
8월 퇴사자 수
0명
연간 퇴사율
0%
총원입/퇴사자
입사자
퇴사자
총원
* 당월 입퇴사자수는 익월 총 고용인원 수에 반영될 수 있습니다.
* 연간 퇴사율은 최근 12개월 간의 퇴사자 수 합을 연평균 인원수로 나눈 값입니다.
* 개인사업자 혹은 2인 이하 법인 사업자의 데이터는 노출되지 않을 수 있습니다.
* 출처:
국민연금, 기업의 직접 제보 데이터 등을 바탕으로 제공합니다.
ㄴ 국민연금 데이터의 경우 국민연금의 고지 인원수를 기반한 값으로, 정규직 이외 비정규직 데이터(인원 수 및 퇴사율)도 포함될 수 있습니다.
ㄴ 직접 제보 데이터는 해당 기업으로부터 제보받아 혁신의숲 검수가 완료된 고용 데이터를 의미합니다.
손익
업데이트 : 2024-09-25

데이터제보/오류신고
원
매출액
영업이익
순이익
구분	2023	2022	2021
매출액	비공개	비공개	비공개
영업이익	비공개	비공개	비공개
순이익	비공개	비공개	비공개
* 혁신의숲에 등록된 기업 손익 데이터는 사업자등록증 상의 명칭으로 등록된 데이터에 한하며, 기업의 자회사나 계열사의 손익 데이터는 반영되지 않을 수 있습니다.
* 매출액은 총매출액, 영업이익은 영업수익에서 영업비용을 제외한 값, 순이익은 영업 외 수익을 모두 포함한 값입니다.
* 실제 손익데이터는 감사보고서 혹은 공시된 재무자료와 상이할 수 있습니다.
* 매출액, 영업이익, 순이익 데이터는 각 년도별 세부 정보가 반영되지 않을 수 있습니다.
 */}
      </div>
   );
};

export default FourSales;
