// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CompanyList.css */

/* Styles for the company list */
.companylist-item {
   list-style-type: none; /* Removes bullets from the list */
   padding: 0; /* Removes default padding */
   margin: 0; /* Removes default margin */
}

/* Example styling for individual items */
.companylist-item li {
   border: 1px solid #ddd;
   padding: 10px;
   margin: 10px 0;
   border-radius: 5px;
   background-color: #f9f9f9;
   transition: background-color 0.3s ease;
}

/* Hover effect for list items */
.companylist-item li:hover {
   background-color: #f1f1f1;
}

/* Additional styles as needed */
`, "",{"version":3,"sources":["webpack://./src/components/CompanyList.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB,gCAAgC;AAChC;GACG,qBAAqB,EAAE,kCAAkC;GACzD,UAAU,EAAE,4BAA4B;GACxC,SAAS,EAAE,2BAA2B;AACzC;;AAEA,yCAAyC;AACzC;GACG,sBAAsB;GACtB,aAAa;GACb,cAAc;GACd,kBAAkB;GAClB,yBAAyB;GACzB,sCAAsC;AACzC;;AAEA,gCAAgC;AAChC;GACG,yBAAyB;AAC5B;;AAEA,gCAAgC","sourcesContent":["/* CompanyList.css */\r\n\r\n/* Styles for the company list */\r\n.companylist-item {\r\n   list-style-type: none; /* Removes bullets from the list */\r\n   padding: 0; /* Removes default padding */\r\n   margin: 0; /* Removes default margin */\r\n}\r\n\r\n/* Example styling for individual items */\r\n.companylist-item li {\r\n   border: 1px solid #ddd;\r\n   padding: 10px;\r\n   margin: 10px 0;\r\n   border-radius: 5px;\r\n   background-color: #f9f9f9;\r\n   transition: background-color 0.3s ease;\r\n}\r\n\r\n/* Hover effect for list items */\r\n.companylist-item li:hover {\r\n   background-color: #f1f1f1;\r\n}\r\n\r\n/* Additional styles as needed */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
