import React from 'react';

function CheckboxInput({ value, label, checked, onChange }) {
   return (
      <label className="filter-item">
         <input type="checkbox" value={value} checked={checked} onChange={onChange} />
         {label}
      </label>
   );
}

export default CheckboxInput;
