// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CompanyItem.css */

/* Card container */
.company-card {
   border: 1px solid #ddd;
   border-radius: 8px;
   padding: 15px;
   margin-bottom: 10px;
   background-color: #fff;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Card hover effect */
.company-card:hover {
   transform: translateY(-2px);
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Company name */
.company-name {
   font-size: 24px;
   font-weight: bold;
   padding-top: 0px;
   margin-top: 0px;
   margin-bottom: 5px;
   color: #333;
}

/* Company details */
.company-details {
   font-size: 14px;
   color: #666;
   /* margin-top: 0px;
   margin-bottom: 0px;
   padding-top: 0px;
   padding-bottom: 0px; */
   /* align-items: left; */
}

.company-details p {
   text-align: left;
   font-size: 14px;
   margin-top: 0px;
   margin-bottom: 0px;
   padding-top: 0px;
   padding-bottom: 0px;
}

/* Individual detail items */
.company-category,
.company-location,
.company-description {
   margin: 0px 0;
}

/* Ensure the description stands out */
.company-description {
   color: #333;
   /*font-style: italic; */
}
`, "",{"version":3,"sources":["webpack://./src/components/CompanyItem.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB,mBAAmB;AACnB;GACG,sBAAsB;GACtB,kBAAkB;GAClB,aAAa;GACb,mBAAmB;GACnB,sBAAsB;GACtB,wCAAwC;GACxC,qDAAqD;AACxD;;AAEA,sBAAsB;AACtB;GACG,2BAA2B;GAC3B,yCAAyC;AAC5C;;AAEA,iBAAiB;AACjB;GACG,eAAe;GACf,iBAAiB;GACjB,gBAAgB;GAChB,eAAe;GACf,kBAAkB;GAClB,WAAW;AACd;;AAEA,oBAAoB;AACpB;GACG,eAAe;GACf,WAAW;GACX;;;yBAGsB;GACtB,uBAAuB;AAC1B;;AAEA;GACG,gBAAgB;GAChB,eAAe;GACf,eAAe;GACf,kBAAkB;GAClB,gBAAgB;GAChB,mBAAmB;AACtB;;AAEA,4BAA4B;AAC5B;;;GAGG,aAAa;AAChB;;AAEA,sCAAsC;AACtC;GACG,WAAW;GACX,uBAAuB;AAC1B","sourcesContent":["/* CompanyItem.css */\r\n\r\n/* Card container */\r\n.company-card {\r\n   border: 1px solid #ddd;\r\n   border-radius: 8px;\r\n   padding: 15px;\r\n   margin-bottom: 10px;\r\n   background-color: #fff;\r\n   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n   transition: transform 0.3s ease, box-shadow 0.3s ease;\r\n}\r\n\r\n/* Card hover effect */\r\n.company-card:hover {\r\n   transform: translateY(-2px);\r\n   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n/* Company name */\r\n.company-name {\r\n   font-size: 24px;\r\n   font-weight: bold;\r\n   padding-top: 0px;\r\n   margin-top: 0px;\r\n   margin-bottom: 5px;\r\n   color: #333;\r\n}\r\n\r\n/* Company details */\r\n.company-details {\r\n   font-size: 14px;\r\n   color: #666;\r\n   /* margin-top: 0px;\r\n   margin-bottom: 0px;\r\n   padding-top: 0px;\r\n   padding-bottom: 0px; */\r\n   /* align-items: left; */\r\n}\r\n\r\n.company-details p {\r\n   text-align: left;\r\n   font-size: 14px;\r\n   margin-top: 0px;\r\n   margin-bottom: 0px;\r\n   padding-top: 0px;\r\n   padding-bottom: 0px;\r\n}\r\n\r\n/* Individual detail items */\r\n.company-category,\r\n.company-location,\r\n.company-description {\r\n   margin: 0px 0;\r\n}\r\n\r\n/* Ensure the description stands out */\r\n.company-description {\r\n   color: #333;\r\n   /*font-style: italic; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
