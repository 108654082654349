import React from 'react';
import CheckboxInput from './CheckboxInput';

function IndustryFilter({ industryFilter, setIndustryFilter }) {
   const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      setIndustryFilter((prevFilters) => (checked ? [...prevFilters, value] : prevFilters.filter((filter) => filter !== value)));
   };

   return (
      <div className="filter-box">
         <h4 className="filter-title">업종</h4>
         <CheckboxInput value="IT" label="IT" checked={industryFilter.includes('IT')} onChange={handleCheckboxChange} />
         <CheckboxInput value="헬스케어" label="헬스케어" checked={industryFilter.includes('헬스케어')} onChange={handleCheckboxChange} />
         <CheckboxInput value="제조" label="제조" checked={industryFilter.includes('제조')} onChange={handleCheckboxChange} />
      </div>
   );
}

export default IndustryFilter;
