// src/theme.js

const theme = {
   primaryColor: '#5885AF', // Samsung blue
   secondaryColor: '#00a8e8', // Samsung light blue
   backgroundColor: 'white', // Light background
   textColor: '#333333', // Dark text color
   borderColor: '#e0e0e0', // Light border
};

export default theme;
