// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.final-page-container {
   position: fixed; /* Fix position to cover the viewport */
   top: 0;
   left: 0;
   width: 100vw; /* Full viewport width */
   height: 100vh; /* Full viewport height */
   overflow: hidden; /* Prevent any overflow */
   z-index: 9999; /* Ensure it is on top of other content */
}

.final-page {
   width: 100%; /* Make image fill the width of the container */
   height: 100%; /* Make image fill the height of the container */
   object-fit: cover; /* Maintain aspect ratio and cover the entire area */
   position: absolute; /* Position absolute to avoid layout issues */
   top: 0; /* Align to the top */
   left: 0; /* Align to the left */
}
`, "",{"version":3,"sources":["webpack://./src/pages/FinalPage.css"],"names":[],"mappings":"AAAA;GACG,eAAe,EAAE,uCAAuC;GACxD,MAAM;GACN,OAAO;GACP,YAAY,EAAE,wBAAwB;GACtC,aAAa,EAAE,yBAAyB;GACxC,gBAAgB,EAAE,yBAAyB;GAC3C,aAAa,EAAE,yCAAyC;AAC3D;;AAEA;GACG,WAAW,EAAE,+CAA+C;GAC5D,YAAY,EAAE,gDAAgD;GAC9D,iBAAiB,EAAE,oDAAoD;GACvE,kBAAkB,EAAE,6CAA6C;GACjE,MAAM,EAAE,qBAAqB;GAC7B,OAAO,EAAE,sBAAsB;AAClC","sourcesContent":[".final-page-container {\r\n   position: fixed; /* Fix position to cover the viewport */\r\n   top: 0;\r\n   left: 0;\r\n   width: 100vw; /* Full viewport width */\r\n   height: 100vh; /* Full viewport height */\r\n   overflow: hidden; /* Prevent any overflow */\r\n   z-index: 9999; /* Ensure it is on top of other content */\r\n}\r\n\r\n.final-page {\r\n   width: 100%; /* Make image fill the width of the container */\r\n   height: 100%; /* Make image fill the height of the container */\r\n   object-fit: cover; /* Maintain aspect ratio and cover the entire area */\r\n   position: absolute; /* Position absolute to avoid layout issues */\r\n   top: 0; /* Align to the top */\r\n   left: 0; /* Align to the left */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
