import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Dataroom.css'; // Make sure to create and import this CSS file
import icon1 from '../data/demo/icons/1.webp';

const Dataroom = ({ companies }) => {
   const [recommendationVisible, setRecommendationVisible] = useState(false); // State to manage AI Recommendation row
   const [loading, setLoading] = useState(false); // State to manage loading screen
   const navigate = useNavigate(); // React Router hook for navigation

   const handleAiRecommendation = () => {
      setLoading(true);
      // Show the AI Recommendation after 1 second
      setTimeout(() => {
         setRecommendationVisible(true);
         setLoading(false); // Hide the loading screen
      }, 1000);
   };

   if (loading) {
      return (
         <div className="loading-overlay">
            <div className="loading-content">
               <div className="loading-message">AI 추천중입니다...</div>
               <div className="loading-message">잠시만 기다려 주세요.</div>
               <div className="loading-icons">
                  <span className="loader-icon">⏳</span>
               </div>
            </div>
         </div>
      );
   }

   // const handleAiRecommendation  = () => {
   //    // Show the AI Recommendation
   //    setRecommendation(!recommendation);

   //    if (recommendation) {
   //       set
   //       return (
   //          <div className="loading-overlay">
   //             <div className="loading-content">
   //                <div className="loading-message">가상IR환경으로 이동중입니다...</div>
   //                <div className="loading-message">잠시만 기다려 주세요.</div>
   //                <div className="loading-icons">
   //                   <span className="loader-icon">⏳</span>
   //                </div>
   //             </div>
   //          </div>
   //       );
   //    }

   // };

   return (
      <div className="main-container">
         <div className="investor-navbar">
            {/* <div className="navbar-items"> */}
            <button className="floating-button" onClick={handleAiRecommendation}>
               <i className="fa-solid fa-robot"></i>
               <span className="ai-recommend text">AI추천</span>
            </button>
            <div className="investor-navbar-select-container">
               <select className="investor-navbar-select">
                  <option>비즈니스 분야</option>
                  <option>광고/마케팅</option>
                  <option>교육</option>
                  <option>금융/보험/핀테크</option>
                  <option>게임</option>
                  <option>모빌리티/교통</option>
                  <option>물류</option>
                  <option>부동산/건설</option>
                  <option>뷰티/화장품</option>
                  <option>AI/딥테크/블록체인</option>
                  <option>소셜미디어/커뮤니티</option>
                  <option>여행/레저</option>
                  <option>유아/출산</option>
                  <option>인사/비즈니스/법률</option>
                  <option>제조/하드웨어</option>
                  <option>커머스</option>
                  <option>콘텐츠/예술</option>
                  <option>통신/보안/데이터</option>
                  <option>패션</option>
                  <option>푸드/농업</option>
                  <option>환경/에너지</option>
                  <option>홈리빙/펫</option>
                  <option>헬스케어/바이오</option>
                  <option>피트니스/스포츠</option>
                  <option>기타</option>
               </select>
               <select className="investor-navbar-select">
                  <option>활용 기술</option>
                  <option>AI</option>
                  <option>XR</option>
                  <option>데이터</option>
                  <option>로보틱스</option>
                  <option>블록체인</option>
                  <option>사물인터넷</option>
                  <option>신소재</option>
                  <option>에너지</option>
                  <option>제조</option>
                  <option>클라우드</option>
                  <option>기타</option>
               </select>
               <select className="investor-navbar-select">
                  <option>최근 투자 단계</option>
                  <option>Seed</option>
                  <option>pre-A</option>
                  <option>pre-B</option>
                  <option>Series A</option>
                  <option>Series B</option>
                  <option>Series C</option>
                  <option>Series D</option>
                  <option>Series E</option>
                  <option>Series F</option>
                  <option>Series G</option>
                  <option>pre-IPO</option>
                  <option>IPO</option>
                  <option>M&A</option>
                  <option>비공개</option>
               </select>
               <div className="investor-navbar-checkbox">
                  <label htmlFor="investment-status">투자 유치 중</label>
                  <input type="checkbox" id="investment-status" />
               </div>
            </div>
            <div className="investor-navbar-search">
               <input type="text" className="investor-navbar-search-text" placeholder="스타트업을 검색해보세요." />
               <button className="investor-navbar-search-button">🔍</button>
            </div>
            {/* </div> */}
         </div>
         <table className="css-8sdmb">
            <caption></caption>

            <thead>
               <tr>
                  <th className="cs-th-0 th dataroom-th">기업명</th>
                  <th className="cs-th-1 th dataroom-th">기업설명</th>
                  <th className="cs-th-2 th dataroom-th">최종투자단계</th>
                  <th className="cs-th-3 th dataroom-th">누적투자금액</th>
                  <th className="cs-th-4 th dataroom-th">매출</th>
                  <th className="cs-th-5 th dataroom-th">고용인원</th>
                  <th className="cs-th-6 th dataroom-th">카테고리</th>
                  <th className="cs-th-7 th dataroom-th">키워드</th>
               </tr>
            </thead>
            <tbody>
               {companies.slice(0, 3).map(
                  (
                     company,
                     index // First 4 rows
                  ) => (
                     <tr className="css-0" key={index}>
                        <td className="cs-td-0">
                           <Link to={`/dataroom/${company.nickname}`} className="link company-name-container">
                              <div className="icon-container">
                                 <img src={require(`../data/demo/icons/${index + 1}.webp`)} alt="Placeholder 1" className="company-icon" />
                              </div>

                              <div className="company-name">{company.company_name}</div>
                           </Link>
                        </td>
                        <td className="cs-td-1">
                           {company.description.length > 30 ? company.description.substring(0, 30) + '...' : company.description}
                        </td>
                        <td className="cs-td-2">{company.funding_level}</td>
                        <td className="cs-td-3">{company.accum_funding_amt}</td>
                        <td className="cs-td-4">{company.revenue}</td>
                        <td className="cs-td-5">{company.number_employees}</td>
                        <td className="cs-td-6">{company.category.length > 30 ? company.category.substring(0, 30) + '...' : company.category}</td>
                        <td className="cs-td-7">
                           {typeof company.keywords === 'string'
                              ? company.keywords.length > 30
                                 ? company.keywords.substring(0, 30) + '...'
                                 : company.keywords
                              : ''}
                        </td>
                     </tr>
                  )
               )}

               {/* Merged cell in the fifth row 
               <tr>
                  <td colSpan="8" className="merged-cell">
                     <div className="ai-recommend">
                        <i className="fa-solid fa-robot"></i>
                        <span className="ai-recommend text">AI추천</span>
                     </div>
                     <div className="ai-recommend description">
                        "포세일즈"는 현재 스케일 업을 위해 공장 재고자산에 대한 실시간 data 연동을 시도하고 있어요.
                        <br />
                        "삼성화재"의 화재보험과 연계한다면 서로 도움을 줄 수 있을 것 같아요!
                     </div>
                  </td>
               </tr> */}
               {/* Conditionally render the merged cell based on state */}
               {recommendationVisible && (
                  <tr>
                     <td colSpan="8" className="merged-cell">
                        <div className="ai-recommendation-message">
                           <div className="ai-recommend">
                              <i className="fa-solid fa-robot"></i>
                              <span className="ai-recommend text">AI추천</span>
                           </div>
                           <div className="ai-recommend description">
                              "포세일즈"는 현재 스케일 업을 위해 공장 재고자산에 대한 실시간 data 연동을 시도하고 있어요.
                              <br />
                              "삼성화재"의 화재보험과 연계한다면 서로 도움을 줄 수 있을 것 같아요!
                           </div>
                        </div>
                     </td>
                  </tr>
               )}
               {/* {companies.slice(3, 12).map( */}
               {companies.slice(3, 11).map(
                  (
                     company,
                     index // Next 8 rows
                  ) => (
                     <tr className="css-0" key={index + 3}>
                        <td className="cs-td-0">
                           <Link to={`/dataroom/${company.nickname}`} className="link company-name-container">
                              <div className="icon-container">
                                 <img src={require(`../data/demo/icons/${index + 4}.webp`)} alt="Placeholder 1" className="company-icon" />
                              </div>

                              <div className="company-name">{company.company_name}</div>
                           </Link>
                        </td>
                        <td className="cs-td-1">
                           {company.description.length > 30 ? company.description.substring(0, 30) + '...' : company.description}
                        </td>
                        <td className="cs-td-2">{company.funding_level}</td>
                        <td className="cs-td-3">{company.accum_funding_amt}</td>
                        <td className="cs-td-4">{company.revenue}</td>
                        <td className="cs-td-5">{company.number_employees}</td>
                        <td className="cs-td-6">{company.category.length > 30 ? company.category.substring(0, 30) + '...' : company.category}</td>
                        <td className="cs-td-7">
                           {typeof company.keywords === 'string'
                              ? company.keywords.length > 30
                                 ? company.keywords.substring(0, 30) + '...'
                                 : company.keywords
                              : ''}
                        </td>
                     </tr>
                  )
               )}
            </tbody>
         </table>
         <div className="pagination-container">
            <div className="pagination">
               <button className="page-number active">1</button>
               <button className="page-number">2</button>
               <button className="page-number">3</button>
               <button className="page-number">4</button>
               <button className="page-number">5</button>
               <button className="next-page">▶</button>
               <button className="next-page">▶▶</button>
            </div>
         </div>
      </div>
   );
};

export default Dataroom;

// <i class="fa-solid fa-robot"></i>
