import React from 'react';
import {
   conclusion,
   // conclusionNegative,
   conclusionPositiveMarkdown,
   conclusionNegativeMarkdown,
   conclusionPlusOverall,
   conclusionPlusIndustry,
   conclusionPlusTechnical,
   conclusionPlusFinancial,
   conclusionMinusOverall,
   conclusionMinusIndustry,
   conclusionMinusTechnical,
   conclusionMinusFinancial,
} from '../data/demo2/conclusion';
import wordCloud from '../data/demo2/wordCloud.png';
import ReactMarkdown from 'react-markdown';
import mockIrOpinion from '../data/demo2/mock-ir-opinion.png';
import './MockirResult.css';

const MockirResult = () => {
   return (
      <div>
         <table className="conclusion-table">
            <thead>
               <tr>
                  <th className="section-title-mock-ir title">IR자료 요약 </th> {/* Wrapped in <th> for bold and proper table usage */}
               </tr>
            </thead>
            <tbody>
               <tr>
                  <img src={require('../data/demo2/1-1.png')} alt="Placeholder 2" className="tr-one-image smaller-image" />
               </tr>
               <tr>
                  <img src={require('../data/demo2/1.png')} alt="Placeholder 2" className="tr-one-image" />
               </tr>
            </tbody>
         </table>

         <table className="conclusion-table">
            <thead>
               <tr>
                  <th className="section-title-mock-ir">{''} </th> {/* Wrapped in <th> for bold and proper table usage */}
               </tr>
            </thead>
            <tbody>
               <tr>
                  <img src={require('../data/demo2/5.png')} alt="Placeholder 2" className="tr-one-image smaller-image" />
               </tr>
            </tbody>
         </table>
         <table className="conclusion-table">
            <thead>
               <tr>
                  <th className="section-title-mock-ir">{''} </th> {/* Wrapped in <th> for bold and proper table usage */}
               </tr>
            </thead>
            <tbody>
               <tr>
                  <img src={require('../data/demo2/3.png')} alt="Placeholder 2" className="tr-one-image smaller-image2" />
               </tr>
            </tbody>
         </table>
      </div>
   );
};

export default MockirResult;

// category
// Section title : 제목
// sub1 종합검토의견 콘텐츠
// sub2 산업분석 콘텐츠
// sub3 기술분석 콘텐츠
// sub4 재무분석 콘텐츠
// sub5

// category3 3개 pillar 쌓을거
