// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
   margin-top: 0;
   padding-top: 0;
}
.auth-container {
   max-width: 400px;
   margin-top: 0;
   margin: 0px auto;
   padding: 20px;
   border-radius: 8px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   background-color: #fff;
}

h2 {
   text-align: center;
   margin-bottom: 20px;
   margin-top: 0;
}

.form-group {
   margin-bottom: 15px;
}

.form-group label {
   display: block;
   margin-bottom: 5px;
}

.form-group input {
   width: 100%;
   padding: 8px;
   box-sizing: border-box;
}

.auth-button {
   width: 100%;
   padding: 10px;
   background-color: var(--primary-color);
   color: #fff;
   background-color: #5885af;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   font-size: 16px;
}

.auth-button:hover {
   background-color: #004080;
}

p {
   text-align: center;
   margin-top: 15px;
}

p a {
   color: var(--primary-color);
   /* text-decoration: none; */
}

p a:hover {
   text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,cAAc;AACjB;AACA;GACG,gBAAgB;GAChB,aAAa;GACb,gBAAgB;GAChB,aAAa;GACb,kBAAkB;GAClB,yCAAyC;GACzC,sBAAsB;AACzB;;AAEA;GACG,kBAAkB;GAClB,mBAAmB;GACnB,aAAa;AAChB;;AAEA;GACG,mBAAmB;AACtB;;AAEA;GACG,cAAc;GACd,kBAAkB;AACrB;;AAEA;GACG,WAAW;GACX,YAAY;GACZ,sBAAsB;AACzB;;AAEA;GACG,WAAW;GACX,aAAa;GACb,sCAAsC;GACtC,WAAW;GACX,yBAAyB;GACzB,YAAY;GACZ,kBAAkB;GAClB,eAAe;GACf,eAAe;AAClB;;AAEA;GACG,yBAAyB;AAC5B;;AAEA;GACG,kBAAkB;GAClB,gBAAgB;AACnB;;AAEA;GACG,2BAA2B;GAC3B,2BAA2B;AAC9B;;AAEA;GACG,0BAA0B;AAC7B","sourcesContent":[".main-content {\r\n   margin-top: 0;\r\n   padding-top: 0;\r\n}\r\n.auth-container {\r\n   max-width: 400px;\r\n   margin-top: 0;\r\n   margin: 0px auto;\r\n   padding: 20px;\r\n   border-radius: 8px;\r\n   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n   background-color: #fff;\r\n}\r\n\r\nh2 {\r\n   text-align: center;\r\n   margin-bottom: 20px;\r\n   margin-top: 0;\r\n}\r\n\r\n.form-group {\r\n   margin-bottom: 15px;\r\n}\r\n\r\n.form-group label {\r\n   display: block;\r\n   margin-bottom: 5px;\r\n}\r\n\r\n.form-group input {\r\n   width: 100%;\r\n   padding: 8px;\r\n   box-sizing: border-box;\r\n}\r\n\r\n.auth-button {\r\n   width: 100%;\r\n   padding: 10px;\r\n   background-color: var(--primary-color);\r\n   color: #fff;\r\n   background-color: #5885af;\r\n   border: none;\r\n   border-radius: 4px;\r\n   cursor: pointer;\r\n   font-size: 16px;\r\n}\r\n\r\n.auth-button:hover {\r\n   background-color: #004080;\r\n}\r\n\r\np {\r\n   text-align: center;\r\n   margin-top: 15px;\r\n}\r\n\r\np a {\r\n   color: var(--primary-color);\r\n   /* text-decoration: none; */\r\n}\r\n\r\np a:hover {\r\n   text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
