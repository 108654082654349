import React from 'react';
import CheckboxInput from './CheckboxInput';

function LocationFilter({ locationFilter, setLocationFilter }) {
   const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      setLocationFilter((prevFilters) => (checked ? [...prevFilters, value] : prevFilters.filter((filter) => filter !== value)));
   };

   return (
      <div className="filter-box">
         <h4 className="filter-title">위치</h4>
         <CheckboxInput value="서울 강남구" label="서울 강남구" checked={locationFilter.includes('서울 강남구')} onChange={handleCheckboxChange} />
         <CheckboxInput value="서울 종로구" label="서울 종로구" checked={locationFilter.includes('서울 종로구')} onChange={handleCheckboxChange} />
         <CheckboxInput value="서울 마포구" label="서울 마포구" checked={locationFilter.includes('서울 마포구')} onChange={handleCheckboxChange} />
      </div>
   );
}

export default LocationFilter;
