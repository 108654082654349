// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global styles for the entire app */
body {
   font-family: 'Paperlogy', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
   margin: 0;
   padding: 0;
   background-color: #ffffff;
   /* background-color: rgb(12, 45, 72); */
   color: #333;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-weight: 600;
   color: #000; /* Darker color for headings */
}

p,
label,
input,
textarea {
   font-size: 16px;
   line-height: 1.6;
   color: #333;
}

button {
   font-family: inherit;
   font-size: 16px;
   padding: 10px 20px;
   /* background-color: #5885af; Apple-like blue button */
   color: white;
   border: none;
   border-radius: 8px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

button:hover {
   background-color: #5885af; /* Darker blue on hover */
}

input,
textarea {
   font-family: inherit;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 8px;
   width: 100%;
   box-sizing: border-box;
   /* margin-bottom: 20px; */
}

input:focus,
textarea:focus {
   border-color: #5885af;
   outline: none;
   box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;GACG;uBACoB;GACpB,SAAS;GACT,UAAU;GACV,yBAAyB;GACzB,uCAAuC;GACvC,WAAW;GACX,mCAAmC;GACnC,kCAAkC;AACrC;;AAEA;;;;;;GAMG,gBAAgB;GAChB,WAAW,EAAE,8BAA8B;AAC9C;;AAEA;;;;GAIG,eAAe;GACf,gBAAgB;GAChB,WAAW;AACd;;AAEA;GACG,oBAAoB;GACpB,eAAe;GACf,kBAAkB;GAClB,sDAAsD;GACtD,YAAY;GACZ,YAAY;GACZ,kBAAkB;GAClB,eAAe;GACf,sCAAsC;AACzC;;AAEA;GACG,yBAAyB,EAAE,yBAAyB;AACvD;;AAEA;;GAEG,oBAAoB;GACpB,aAAa;GACb,sBAAsB;GACtB,kBAAkB;GAClB,WAAW;GACX,sBAAsB;GACtB,yBAAyB;AAC5B;;AAEA;;GAEG,qBAAqB;GACrB,aAAa;GACb,0CAA0C;AAC7C","sourcesContent":["/* Global styles for the entire app */\nbody {\n   font-family: 'Paperlogy', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',\n      'Segoe UI Symbol';\n   margin: 0;\n   padding: 0;\n   background-color: #ffffff;\n   /* background-color: rgb(12, 45, 72); */\n   color: #333;\n   -webkit-font-smoothing: antialiased;\n   -moz-osx-font-smoothing: grayscale;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n   font-weight: 600;\n   color: #000; /* Darker color for headings */\n}\n\np,\nlabel,\ninput,\ntextarea {\n   font-size: 16px;\n   line-height: 1.6;\n   color: #333;\n}\n\nbutton {\n   font-family: inherit;\n   font-size: 16px;\n   padding: 10px 20px;\n   /* background-color: #5885af; Apple-like blue button */\n   color: white;\n   border: none;\n   border-radius: 8px;\n   cursor: pointer;\n   transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n   background-color: #5885af; /* Darker blue on hover */\n}\n\ninput,\ntextarea {\n   font-family: inherit;\n   padding: 10px;\n   border: 1px solid #ccc;\n   border-radius: 8px;\n   width: 100%;\n   box-sizing: border-box;\n   /* margin-bottom: 20px; */\n}\n\ninput:focus,\ntextarea:focus {\n   border-color: #5885af;\n   outline: none;\n   box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
