// import React, { useState } from 'react';
import React from 'react';
// import CompanyForm from '../components/CompanyForm';
import CompanyList from '../components/CompanyList';
// import FiltersBar from '../components/Filters/FiltersBar';
import '../components/Filters/FiltersBar.css'; // Import the CSS

function Companies({ companies, addCompany }) {
   // const [industryFilter, setIndustryFilter] = useState([]);
   // const [stageFilter, setStageFilter] = useState([]);
   // const [locationFilter, setLocationFilter] = useState([]);

   // const filteredCompaniesList = companies.filter((company) => {
   //    return (
   //       (industryFilter.length === 0 || industryFilter.includes(company.industry)) &&
   //       (stageFilter.length === 0 || stageFilter.includes(company.stage)) &&
   //       (locationFilter.length === 0 || locationFilter.includes(company.location))
   //    );
   // });

   return (
      <div style={{ padding: '0 10px' }}>
         {/* <FiltersBar */}
         {/* // industryFilter={industryFilter}
         // setIndustryFilter={setIndustryFilter}
         // stageFilter={stageFilter}
         // setStageFilter={setStageFilter}
         // locationFilter={locationFilter}
         // setLocationFilter={setLocationFilter}
         // /> */}
         {/* <h2>스타트업 List</h2> */}
         {/* <CompanyList companies={filteredCompaniesList} /> */}
         <CompanyList companies={companies} />
      </div>
   );
}

export default Companies;
