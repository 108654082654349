// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Paperlogy-1.000/Paperlogy-4Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/GmarketSansOTF/GmarketSansMedium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
   font-family: 'Paperlogy';
   src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype'); /* You can also use .woff or .woff2 */
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Gmarket';
   src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
   font-weight: normal;
   font-style: normal;
}

body {
   font-family: 'Paperlogy', sans-serif;
   /* background-color: rgb(12, 45, 72); */
   /* font-family: sans-serif; */
}

.filter-box {
   background-color: #ffffff;
   border: 1px solid #e5e5e5;
   border-radius: 8px;
   padding: 10px;
   margin-right: 20px;
   min-width: 150px;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-title {
   font-size: 14px;
   font-weight: bold;
   margin-bottom: 8px;
   color: #333;
}

.filter-item {
   display: block;
   font-size: 14px;
   margin-bottom: 5px;
   color: #333;
}

.filter-item input {
   margin-right: 8px;
   vertical-align: middle;
}

.main-content {
   padding-top: 70px; /* Adjust this value based on the navbar height */
   padding-left: 20px;
   padding-right: 20px;
   height: 94vh;
}

.main-content.interview {
   background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;GACG,wBAAwB;GACxB,+DAAoF,EAAE,qCAAqC;GAC3H,mBAAmB;GACnB,kBAAkB;AACrB;;AAEA;GACG,sBAAsB;GACtB,+DAAkF;GAClF,mBAAmB;GACnB,kBAAkB;AACrB;;AAEA;GACG,oCAAoC;GACpC,uCAAuC;GACvC,6BAA6B;AAChC;;AAEA;GACG,yBAAyB;GACzB,yBAAyB;GACzB,kBAAkB;GAClB,aAAa;GACb,kBAAkB;GAClB,gBAAgB;GAChB,0CAA0C;AAC7C;;AAEA;GACG,eAAe;GACf,iBAAiB;GACjB,kBAAkB;GAClB,WAAW;AACd;;AAEA;GACG,cAAc;GACd,eAAe;GACf,kBAAkB;GAClB,WAAW;AACd;;AAEA;GACG,iBAAiB;GACjB,sBAAsB;AACzB;;AAEA;GACG,iBAAiB,EAAE,iDAAiD;GACpE,kBAAkB;GAClB,mBAAmB;GACnB,YAAY;AACf;;AAEA;GACG,uBAAuB;AAC1B","sourcesContent":["@font-face {\n   font-family: 'Paperlogy';\n   src: url('./assets/fonts/Paperlogy-1.000/Paperlogy-4Regular.ttf') format('truetype'); /* You can also use .woff or .woff2 */\n   font-weight: normal;\n   font-style: normal;\n}\n\n@font-face {\n   font-family: 'Gmarket';\n   src: url('./assets/fonts/GmarketSansOTF/GmarketSansMedium.otf') format('truetype');\n   font-weight: normal;\n   font-style: normal;\n}\n\nbody {\n   font-family: 'Paperlogy', sans-serif;\n   /* background-color: rgb(12, 45, 72); */\n   /* font-family: sans-serif; */\n}\n\n.filter-box {\n   background-color: #ffffff;\n   border: 1px solid #e5e5e5;\n   border-radius: 8px;\n   padding: 10px;\n   margin-right: 20px;\n   min-width: 150px;\n   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.filter-title {\n   font-size: 14px;\n   font-weight: bold;\n   margin-bottom: 8px;\n   color: #333;\n}\n\n.filter-item {\n   display: block;\n   font-size: 14px;\n   margin-bottom: 5px;\n   color: #333;\n}\n\n.filter-item input {\n   margin-right: 8px;\n   vertical-align: middle;\n}\n\n.main-content {\n   padding-top: 70px; /* Adjust this value based on the navbar height */\n   padding-left: 20px;\n   padding-right: 20px;\n   height: 94vh;\n}\n\n.main-content.interview {\n   background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
