import React from 'react';
import './Home.css';

function Home() {
   return (
      <div className="home-image-wrapper">
         {/* <div style={{ paddingTop: '100px' }}>
            <h2> WELCOME TO Growth·4·All </h2>
            <p>
               Growth·4·All 스타트업 투자 검토 플랫폼에 오신 것을 환영합니다. 이 플랫폼은 혁신적인 스타트업을 탐색하고 투자 기회를 검토할 수 있도록
               지원합니다.
            </p>
            <p>성공적인 투자 결정을 위해 스타트업의 성장 가능성을 분석하고, 시장 트렌드에 맞춘 투자 전략을 세울 수 있습니다.</p>
         </div> */}
         <img src={require(`../data/frontgate.png`)} alt="Placeholder 1" className="frontgate" />
      </div>
   );
}

export default Home;
