import React from 'react';
// import theme from '../theme';
import './CompanyItem.css';

function CompanyItem({ company }) {
   return (
      <div className="company-card">
         <h3 className="company-name">{company.company_name}</h3>
         <div className="company-details">
            {/* <p className="company-name">기업명: {company.company_name}</p> */}
            <p className="company-description"> {company.description}</p>
            <p className="company-funding_level">최종투자단계: {company.funding_level}</p>
            <p className="company-accum_funding_amt">누적투자유치금액: {company.accum_funding_amt}</p>
            <p className="company-revenue">매출 : {company.revenue}</p>
            <p className="company-number_employees">직원수 : {company.number_employees}</p>
            <p className="company-category">카테고리 : {company.category}</p>
            <p className="company-keywords">키워드 : {company.keywords}</p>
         </div>
      </div>
   );
}

export default CompanyItem;
