import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import theme from '../theme';
import './Navbar.css';
import './Navbar2.css';
// import

function Navbar({ isAuthenticated, handleSignOut }) {
   const location = useLocation();

   const [isOpen, setIsOpen] = useState(false);
   const sidebarRef = useRef(null); // Reference to the sidebar element

   const toggleSidebar = () => {
      setIsOpen(!isOpen);
   };

   const closeSidebar = () => {
      setIsOpen(false);
   };

   useEffect(() => {
      // Function to handle clicks outside the sidebar
      const handleClickOutside = (event) => {
         if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            closeSidebar();
         }
      };

      // Add event listener to detect outside clicks
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
         // Cleanup the event listener on component unmount
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [sidebarRef]);

   // 스타트업 Room     스타트업 Finder     지원사업     IR 자료     인사이트                        구독/결제     로그아웃
   return (
      <nav className="navbar" style={{ backgroundColor: theme.primaryColor, color: '#fff' }}>
         <div className="navbar-container">
            <Link to="/" className="navbar-logo" onClick={closeSidebar}>
               Growth·4·All
            </Link>
            {/* <button className="menu-button" onClick={toggleSidebar}>
               <i className="fas fa-bars"></i>
            </button> */}
            <div className="navbar-item-container">
               <ul className="navbar-menu2">
                  <li className="navbar-item2">
                     <Link to="/startuproom" className={`navbar-link2 ${location.pathname.startsWith('/startuproom') ? 'active' : ''}`}>
                        스타트업Room
                     </Link>
                  </li>
                  <li className="navbar-item2">
                     <Link to="/dataroom" className={`navbar-link2 ${location.pathname.startsWith('/dataroom') ? 'active' : ''}`}>
                        스타트업Finder
                     </Link>
                  </li>
                  <li className="navbar-item2">
                     <Link className={`navbar-link2 ${location.pathname.startsWith('/xxxxxxx') ? 'active' : ''}`}>지원사업</Link>
                  </li>
                  <li className="navbar-item2">
                     <Link className={`navbar-link2 ${location.pathname.startsWith('/xxxxxxx') ? 'active' : ''}`}>IR자료</Link>
                  </li>
                  <li className="navbar-item2">
                     <Link className={`navbar-link2 ${location.pathname.startsWith('/xxxxxxx') ? 'active' : ''}`}>인사이트</Link>
                  </li>
                  <li className="navbar-item2">
                     <Link className={`navbar-link2 ${location.pathname.startsWith('/xxxxxxx') ? 'active' : ''}`}>구독/결제</Link>
                  </li>
                  <li className="navbar-item2">
                     <Link to="/finalPage" className={`navbar-link2 ${location.pathname.startsWith('/dataroom') ? 'active' : ''}`}>
                        기대효과
                     </Link>
                  </li>
                  {/* <li className="navbar-item2">
                     <Link to="/companies" className={`navbar-link2 ${location.pathname.startsWith('/companies') ? 'active' : ''}`}>
                        Lists
                     </Link>
                  </li>
                  <li className="navbar-item2">
                     <Link to="/valuecompany" className={`navbar-link2 ${location.pathname.startsWith('/valuecompany') ? 'active' : ''}`}>
                        Evaluation
                     </Link>
                  </li>
                  <li className="navbar-item2">
                     <Link to="/chatbot" className={`navbar-link2 ${location.pathname.startsWith('/xxxxxxx') ? 'active' : ''}`}>
                        Chatbot
                     </Link>
                  </li> */}
               </ul>
               <button className="signout-button">
                  <i class="fa-solid fa-power-off"></i>
                  로그아웃
               </button>
            </div>
         </div>

         <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`}>
            {/* <button className="close-button" onClick={toggleSidebar}>
               <i className="fas fa-times"></i>
            </button> */}
            {/* <ul className="navbar-menu">
               <li className="navbar-item">
                  <Link to="/demo" className="navbar-link" onClick={closeSidebar}>
                     Demo1
                  </Link>
               </li>
               <li className="navbar-item">
                  <Link to="/demo2" className="navbar-link" onClick={closeSidebar}>
                     Demo2
                  </Link>
               </li>
               <li className="navbar-item">
                  <Link to="/companies" className="navbar-link" onClick={closeSidebar}>
                     Lists
                  </Link>
               </li>
               <li className="navbar-item">
                  <Link to="/valuecompany" className="navbar-link" onClick={closeSidebar}>
                     Evaluation
                  </Link>
               </li>
               <li className="navbar-item">
                  <Link to="/chatbot" className="navbar-link" onClick={closeSidebar}>
                     Chatbot
                  </Link>
               </li>
            </ul> */}
            {/* <div className="auth-buttons">
               {isAuthenticated ? (
                  <button
                     onClick={() => {
                        handleSignOut();
                        closeSidebar();
                     }}
                     className="auth-button sign-out-button"
                  >
                     Sign Out
                  </button>
               ) : (
                  <>
                     <Link to="/signin" className="auth-button sign-in-button" onClick={closeSidebar}>
                        로그인
                     </Link>
                     <Link to="/signup" className="auth-button sign-up-button" onClick={closeSidebar}>
                        회원가입
                     </Link>
                  </>
               )}
            </div> */}
         </div>
      </nav>
   );
}

export default Navbar;
