import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import './ChatbotDemo2.css'; // Make sure to create and import this CSS file
import openAIInput from '../data/demo2/promptDemo2';

const Chatbot = () => {
   const [input, setInput] = useState('');
   const [conversation, setConversation] = useState([
      {
         role: 'assistant',
         content: '안녕하세요, Growth·4·All은 스타트업의 성장을 기원합니다. 원하시는 도움이 있으시면 알려주세요! ',
      },
   ]);

   // Ref for the chat window and textarea
   const chatWindowRef = useRef(null);
   const inputRef = useRef(null);
   const sendButtonRef = useRef(null);

   // Import API key from the environment variable
   const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

   // Scroll to the bottom of the chat window
   const scrollToBottom = () => {
      if (chatWindowRef.current) {
         chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
   };

   useEffect(() => {
      scrollToBottom();
   }, [conversation]); // Trigger scroll whenever conversation updates

   // Function to resize the textarea dynamically
   useEffect(() => {
      const adjustTextareaHeight = () => {
         const textarea = inputRef.current;
         const sendButton = sendButtonRef.current;
         textarea.style.height = 'auto';
         textarea.style.height = `${textarea.scrollHeight}px`; // Adjust the textarea height based on scrollHeight
         sendButton.style.height = `${textarea.scrollHeight}px`; // Sync button height with textarea
      };

      // Add event listener for input changes
      const textarea = inputRef.current;
      textarea.addEventListener('input', adjustTextareaHeight);

      // Clean up the event listener when the component unmounts
      return () => {
         textarea.removeEventListener('input', adjustTextareaHeight);
      };
   }, []); // Empty dependency array ensures this effect runs once on mount

   const handleSubmit = async () => {
      if (!input.trim()) return; // Don't allow empty messages

      // Add the user's message to the conversation
      const userMessage = { role: 'user', content: input };
      setConversation((prevConversation) => [...prevConversation, userMessage]);
      setInput(''); // Clear the textarea after sending

      // const openAIInput = openAIInput;

      try {
         // Send the user's message to OpenAI API
         const result = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
               model: 'gpt-4o',
               messages: [
                  { role: 'assistant', content: 'You are a financial expert evaluating companies for investment.' },
                  { role: 'user', content: openAIInput },
                  ...conversation,
                  userMessage,
               ], // Include the full conversation in each request
               max_tokens: 1000,
            },
            {
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${apiKey}`,
               },
            }
         );

         // Add GPT's response to the conversation
         const gptResponse = { role: 'assistant', content: result.data.choices[0].message.content };
         setConversation((prevConversation) => [...prevConversation, gptResponse]);
      } catch (error) {
         console.error('Error calling OpenAI API:', error);
      }

      setInput(''); // Clear the input field after sending
   };

   // Function to handle key presses in the textarea
   const handleKeyPress = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
         e.preventDefault(); // Prevent newline from being added
         handleSubmit(); // Send the message
      }
   };

   return (
      <div className="chatbot-wrapper2">
         <div className="chat-functions2">
            <div className="function-button-container2">
               <button className="function-button2">IR전문</button>
               <button className="function-button2">노무</button>
               <button className="function-button2">법률</button>
               <button className="function-button2">기타</button>
               <button className="function-button2">마음누리</button>
            </div>
         </div>
         <div className="chatbot-container2">
            <div className="chat-window2" ref={chatWindowRef}>
               {/* <div className="chat-all"> */}
               {conversation.map((msg, index) => (
                  <div key={index} className={`chat-bubble ${msg.role === 'user' ? 'user' : 'assistant'}`}>
                     {/* {msg.content} */}

                     {/* <ReactMarkdown key={index} className={`chat-bubble ${msg.role === 'user' ? 'user' : 'assistant'}`}> */}
                     <ReactMarkdown>{msg.content}</ReactMarkdown>
                  </div>
               ))}
            </div>
            <div className="chat-input-area2">
               <button className="plus-button2">+</button>
               <textarea
                  ref={inputRef}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress} // Add event listener for keypress
                  rows="2"
                  placeholder="Type your message..."
                  className="chat-input2"
               />
               <button ref={sendButtonRef} onClick={handleSubmit} className="send-button2">
                  <i class="fas fa-paper-plane"></i>
               </button>
            </div>
         </div>
      </div>
   );
};

export default Chatbot;
