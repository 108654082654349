// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frontgate {
   max-width: 100%; /* Ensure the image is responsive */
   height: auto; /* Maintain aspect ratio */
   max-height: 100%;
}

.home-image-wrapper {
   display: flex; /* Enable flexbox */
   justify-content: center; /* Center horizontally */
   align-items: center; /* Center vertically */
   width: 100vw; /* Full width of the parent */
   padding: 10px;
   margin-left: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;GACG,eAAe,EAAE,mCAAmC;GACpD,YAAY,EAAE,0BAA0B;GACxC,gBAAgB;AACnB;;AAEA;GACG,aAAa,EAAE,mBAAmB;GAClC,uBAAuB,EAAE,wBAAwB;GACjD,mBAAmB,EAAE,sBAAsB;GAC3C,YAAY,EAAE,6BAA6B;GAC3C,aAAa;GACb,gBAAgB;AACnB","sourcesContent":[".frontgate {\r\n   max-width: 100%; /* Ensure the image is responsive */\r\n   height: auto; /* Maintain aspect ratio */\r\n   max-height: 100%;\r\n}\r\n\r\n.home-image-wrapper {\r\n   display: flex; /* Enable flexbox */\r\n   justify-content: center; /* Center horizontally */\r\n   align-items: center; /* Center vertically */\r\n   width: 100vw; /* Full width of the parent */\r\n   padding: 10px;\r\n   margin-left: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
